<template>
  <div style="height: calc(100% - 63px)">
    <el-row class="consume_content">
      <el-col :span="9" class="project_left">
        <div class="pad_15">
          <el-input prefix-icon="el-icon-search" placeholder="请输入商品名称、别名关键字，按回车搜索" v-model="searchName" size="small" clearable @keyup.enter.native="searchGoodsClick" @clear="clearClick"></el-input>
        </div>

        <el-tabs class="consumeGoods" v-model="tabPane" @tab-click="tabClick">
          <el-tab-pane v-if="treatProjectAccountList.length > 0" label="项目" name="0">
            <el-scrollbar class="el-scrollbar_height">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item, index) in treatProjectAccountList" :key="index + 'cpr'" @click.native="consumeProjectClick(item)">
                <div slot="header" class="headerCard">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 1)">消耗记录</span>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效次数：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余数量：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundBalance }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买价格：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买数量：</span>
                      <span>{{ item.Quantity }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="goods-item">
                      <span class="color_maroon">备注：</span>
                      <span class="color_maroon">{{ item.Remark }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane v-if="treatSavingCardAccountList.length > 0" label="储值卡" name="1">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item, index) in treatSavingCardAccountList" :key="index + 'cs'" @click.native="consumeSavingCardClick(item)">
                <div slot="header" class="headerCard">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 2)">消耗记录</span>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效余额：</span>
                      <span>¥ {{ item.ValidBalance | NumFormat }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                        <p>
                          2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；
                        </p>
                        <p>
                          3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余金额：</span>
                      <span>¥ {{ item.TotalBalance | NumFormat }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>剩余金额= 剩余本金+剩余赠额</p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中金额：</span>
                      <span>¥ {{ item.RefundAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买金额：</span>
                    <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-row class="border_bottom">
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余本金：</span>
                      <span>¥ {{ item.Balance | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">剩余赠额：</span>
                      <span>¥ {{ item.LargessBalance | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="goods-item">
                      <span class="color_maroon">备注：</span>
                      <span class="color_maroon">{{ item.Remark }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane v-if="treatTimeCardAccountList.length > 0" label="时效卡" name="2">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card
                class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                :class="index == 0 ? 'martp_10' : ''"
                :body-style="item.IsRefund > 0 ? { padding: '0px', 'background-color': '#F5F7FA' } : { padding: '0px' }"
                :shadow="item.IsRefund > 0 ? 'never' : 'hover'"
                v-for="(item, index) in treatTimeCardAccountList"
                :key="index + 'ct'"
                @click.native="consumeTimeCardClick(item)"
              >
                <div slot="header" class="headerCard">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <el-tag v-if="item.IsRefund" size="mini" class="marlt_5" type="danger">退款处理中</el-tag>
                  <!-- <el-link v-if="item.TargetWeight" type="primary">查看</el-link> -->
                  <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 3)">消耗记录</span>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">周期内消耗次数：</span>
                      <span>{{ item.ConsumeCycleAmount }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          当“周期内消耗次数”超过“消耗周期限制”，则不能使用。
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">消耗周期限制：</span>
                      <span>{{ item.ConsumeCycle == 0 || item.CycleLimitAmount == 0 ? "不限制" : item.CycleLimitAmount + "(次)/" + item.ConsumeCycle + "(天)" }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">累计耗用次数：</span>
                      <span>{{ item.ConsumeAmount }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买金额：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">剩余业绩提成次数：</span>
                      <span>{{ item.PerformanceBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>用于确认员工消耗业绩。</p>
                        <p>
                          比如：购买金额为1000(如果卡为赠送，则按照售卖价格)，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">目标减重：</span>
                      <span> {{ item.TargetWeight }} </span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">已减体重：</span>
                      <span>{{ item.LostWeight }} </span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">初始体重：</span>
                      <span>{{ item.SaleCurrentWeight }} </span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">目标体重：</span>
                      <span> {{ item.CustomerTargetWeight }} </span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">最轻体重：</span>
                      <span> {{ item.MinWeight }} </span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">最近体重：</span>
                      <span> {{ item.LastWeight }} </span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="24">
                    <div class="goods-item">
                      <span class="color_maroon">备注：</span>
                      <span class="color_maroon">{{ item.Remark }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="goods-item">
                      <span class=" color_maroon">开卡经手人：</span>
                      <span class="color_maroon">{{ item.HandlerName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane v-if="treatGeneralCardAccountList.length > 0" label="通用次卡" name="3">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item, index) in treatGeneralCardAccountList" :key="index + 'cg'" @click.native="consumeGeneralCardClick(item)">
                <div slot="header" class="headerCard">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 4)">消耗记录</span>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效次数：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余次数：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundAmount }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买金额：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">卡总次数：</span>
                      <span>{{ item.CardTimes }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="goods-item">
                      <span class=" color_maroon">备注：</span>
                      <span class="color_maroon">{{ item.Remark }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane v-if="treatGoodsPackageCardAccountList.length > 0" label="套餐卡" name="4">
            <el-row class="category_project valueCard_project">
              <el-col :span="4" class="text_center category">
                <el-scrollbar class="el-scrollbar_height border_right">
                  <div v-for="(item, index) in treatGoodsPackageCardAccountList" :key="index + 'cpc'" :class="[currentPackCategoryIndex == index ? 'category_select' : '', 'pad_10_15', 'border_bottom', 'cursor_pointer']" @click="clickPackageCagegory(item, index)">
                    <span class="line_20">{{ item.Name }}</span>
                    <span class="line_20" v-if="item.Alias">({{ item.Alias }})</span>
                    <el-tag v-if="item.IsLargess" size="mini" class="marlt_5 line_20" type="danger">赠</el-tag>
                    <el-popover class="marlt_5" popper-class="popover-package" placement="top-start" width="400" trigger="hover">
                      <el-card :body-style="{ padding: '0px' }">
                        <div slot="header">
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        </div>
                        <el-row class="border_bottom">
                          <el-col :span="12" class="border_right">
                            <div class="goods-item">
                              <span class="goods-lable">购买价格：</span>
                              <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="goods-item">
                              <span class="goods-lable">欠款金额：</span>
                              <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="12" class="border_right">
                            <div class="goods-item">
                              <span class="goods-lable">购买日期：</span>
                              <span>{{ item.BuyDate }}</span>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="goods-item">
                              <span class="goods-lable">有效期：</span>
                              <span>{{ item.ValidDayName }}</span>
                            </div>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <div class="goods-item">
                              <span class=" color_maroon">备注：</span>
                              <span class="color_maroon">{{ item.Remark }}</span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-card>
                      <el-button type="text" style="color: #dcdfe6; padding: 0px" class="font_12" icon="el-icon-info" slot="reference"></el-button>
                    </el-popover>
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="20" class="project" v-loading="packageDetailLoading">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <!-- 套餐卡项目 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in treatGoodsPackageCardAccountDetailsList.Project"
                      :key="index + 'cppr'"
                      @click.native="consumePackageProjectClick(item)"
                    >
                      <div slot="header" class="headerCard">
                        <el-tag size="mini">项目</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 1)">消耗记录</span>
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">有效次数：</span>
                            <span>{{ item.ValidBalance }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>
                                有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                              </p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余数量：</span>
                            <span>{{ item.Balance }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中数量：</span>
                            <span>{{ item.RefundBalance }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买价格：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买数量：</span>
                            <span>{{ item.Quantity }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <div class="goods-item">
                            <span class=" color_maroon">备注：</span>
                            <span class="color_maroon">{{ item.Remark }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡储值卡 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in treatGoodsPackageCardAccountDetailsList.SavingCard"
                      :key="index + 'cps'"
                      @click.native="consumePackageSavingCardClick(currentSelectPackageItem, item)"
                    >
                      <div slot="header" class="headerCard">
                        <el-tag size="mini">储值卡</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 2)">消耗记录</span>
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">可用余额：</span>
                            <span>¥ {{ item.ValidBalance | NumFormat }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                              <p>
                                2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；
                              </p>
                              <p>
                                3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。
                              </p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余金额：</span>
                            <span>¥ {{ item.TotalBalance | NumFormat }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>剩余金额= 剩余本金+剩余赠额</p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中金额：</span>
                            <span>¥ {{ item.RefundAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买金额：</span>
                            <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余本金：</span>
                            <span>¥ {{ item.Balance | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">剩余赠额：</span>
                            <span>¥ {{ item.LargessBalance | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <div class="goods-item">
                            <span class=" color_maroon">备注：</span>
                            <span class="color_maroon">{{ item.Remark }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡时效卡 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in treatGoodsPackageCardAccountDetailsList.TimeCard"
                      :key="index + 'cpt'"
                      @click.native="consumePackageTimeCardClick(currentSelectPackageItem, item)"
                    >
                      <div slot="header" class="headerCard">
                        <el-tag size="mini">时效卡</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 3)">消耗记录</span>
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">周期内消耗次数：</span>
                            <span>{{ item.ConsumeCycleAmount }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>
                                当“周期内消耗次数”超过“消耗周期限制”，则不能使用。
                              </p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">消耗周期限制：</span>
                            <span>{{ item.ConsumeCycle == 0 || item.CycleLimitAmount == 0 ? "不限制" : item.CycleLimitAmount + "(次)/" + item.ConsumeCycle + "(天)" }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">累计耗用次数：</span>
                            <span>{{ item.ConsumeAmount }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买金额：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">业绩提成次数：</span>
                            <span>{{ item.PerformanceBalance }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>用于确认员工消耗业绩。</p>
                              <p>
                                比如：购买金额为1000，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。
                              </p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">目标减重：</span>
                            <span>{{ item.TargetWeight }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">已减体重：</span>
                            <span>{{ item.LostWeight }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">初始体重：</span>
                            <span>{{ item.SaleCurrentWeight }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">目标体重：</span>
                            <span> {{ item.CustomerTargetWeight }} </span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">最轻体重：</span>
                            <span> {{ item.MinWeight }} </span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">最近体重：</span>
                            <span> {{ item.LastWeight }} </span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item"></div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <div class="goods-item">
                            <span class=" color_maroon">备注：</span>
                            <span class="color_maroon">{{ item.Remark }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡通用次卡 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in treatGoodsPackageCardAccountDetailsList.GeneralCard"
                      :key="index + 'cpg'"
                      @click.native="consumePackageGeneralCardClick(item)"
                    >
                      <div slot="header" class="headerCard">
                        <el-tag size="mini">通用次卡</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 5)">消耗记录</span>
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">有效次数：</span>
                            <span>{{ item.ValidBalance }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>
                                有效次数= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                              </p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余次数：</span>
                            <span>{{ item.Balance }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中数量：</span>
                            <span>{{ item.RefundAmount }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买金额：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">卡总次数：</span>
                            <span>{{ item.CardTimes }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <div class="goods-item">
                            <span class=" color_maroon">备注：</span>
                            <span class="color_maroon">{{ item.Remark }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡产品 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in treatGoodsPackageCardAccountDetailsList.Product"
                      :key="index + 'cppd'"
                      @click.native="consumePackageProductClick(item)"
                    >
                      <div slot="header" class="headerCard">
                        <el-tag size="mini">产品</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                        <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 6)">消耗记录</span>
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">有效数量：</span>
                            <span>{{ item.ValidBalance }}</span>
                            <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                              <p>
                                有效数量= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                              </p>
                              <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余数量：</span>
                            <span>{{ item.Balance }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中数量：</span>
                            <span>{{ item.RefundBalance }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买价格：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买数量：</span>
                            <span>{{ item.Quantity }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <div class="goods-item">
                            <span class=" color_maroon">备注：</span>
                            <span class="color_maroon">{{ item.Remark }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane v-if="treatProductAccountList.length > 0" label="产品" name="5">
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card class="marbm_10 marlt_10 marrt_10 cursor_pointer" :class="index == 0 ? 'martp_10' : ''" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item, index) in treatProductAccountList" :key="index + 'cpd'" @click.native="consumeProductClick(item)">
                <div slot="header" class="headerCard">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                  <span style="color: #018dff" class="headerCardCha" @click.stop="chaKanClick(item, 6)">消耗记录</span>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效数量：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover class="marlt_5" placement="top-start" width="240" trigger="hover">
                        <p>
                          有效数量= 剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button type="text" style="color: #dcdfe6" class="font_12 el-popover-botton-tip" icon="el-icon-info" slot="reference"></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余数量：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundBalance }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买价格：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买数量：</span>
                      <span>{{ item.Quantity }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span class="color_maroon">¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="goods-item">
                      <span class=" color_maroon">备注：</span>
                      <span class="color_maroon">{{ item.Remark }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="15" class="project_right">
        <el-container style="height: 100%">
          <el-main>
            <el-scrollbar class="el-scrollbar_height color_333">
              <!--项目-->
              <div v-if="currentSelectProjectList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">项目</el-col>
                    <el-col :span="8">数量</el-col>
                    <el-col :span="7">金额</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in currentSelectProjectList" :key="index + 'spr'">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="8">
                      <div>
                        {{ item.Name }}
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                      </div>
                      <div class="color_red martp_5">
                        <span>¥ {{ item.Price | NumFormat }}</span>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <el-input-number v-model="item.Quantity" :min="1" :max="item.ValidBalance - getCurrentProjectQuantity(item.ID) + item.Quantity" size="mini" style="width: 100px" @change="projectItemQuantityChangeClick(item)"></el-input-number>
                    </el-col>
                    <el-col :span="7">
                      <div>
                        <span class="marrt_15">¥ {{ item.TotalAmount | NumFormat }}</span>
                      </div>
                      <div>
                        <span class="color_gray font_12" v-if="parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity) != 0">
                          卡优惠：
                          <span class="color_green" v-if="parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity) > 0"> +{{ Math.abs(parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity)).toFixed(2) | NumFormat }} </span>
                          <span class="color_red" v-else-if="parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity) < 0"> -{{ Math.abs(parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity)).toFixed(2) | NumFormat }} </span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="1">
                      <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeProjectSelectItemClick(index)"></el-button>
                    </el-col>
                  </el-col>
                  <el-col v-if="item.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" @click.native="employeeHandleClick(0, item, index, handleIndex)" v-for="(handleItem, handleIndex) in item.HandleTypeList" :key="handleIndex">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                            <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--储值卡-->
              <div v-if="currentSelectSavingCardList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">储值卡</el-col>
                    <el-col :span="8">数量</el-col>
                    <el-col :span="7">金额</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row v-for="(item, index) in currentSelectSavingCardList" :key="index + 'ss'">
                  <el-card shadow="never" style="border: 0px">
                    <div slot="header" class="font_13 cursor_pointer" @click="consumeSavingCardClick(item)">
                      <span>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                      </span>
                      <span class="color_gray" style="float: right">
                        <span>
                          <span>剩余金额：</span>
                          <span>¥ {{ parseFloat(item.ValidBalance - getCurrentSavingCardConsumeAmount(item, false)).toFixed(2) | NumFormat }}</span>
                        </span>
                        <span class="marlt_5">
                          <span>(卡余额：</span>
                          <span>¥ {{ parseFloat(item.ValidBalance).toFixed(2) | NumFormat }}</span>
                          <span class="marlt_10">卡抵扣：</span>
                          <span>-¥ {{ parseFloat(getCurrentSavingCardConsumeAmount(item, false)).toFixed(2) | NumFormat }})</span>
                        </span>
                      </span>
                    </div>
                    <el-row v-for="(project, pIndex) in item.Projects" :key="pIndex + 'sav_p_s'">
                      <el-col :span="24" class="pad_10 border_bottom">
                        <el-col :span="24">
                          <el-col :span="8">
                            <div>
                              {{ project.Name }}
                              <span v-if="project.Alias">({{ project.Alias }})</span>
                            </div>
                          </el-col>
                          <el-col :span="8">
                            <el-input-number v-model="project.Quantity" :min="1" size="mini" style="width: 100px" @change="(currentValue, oldValue) => changeSavingCardConsumeProject(currentValue, oldValue, item, project)"></el-input-number>
                          </el-col>
                          <el-col :span="7">
                            <span class="marrt_15">{{ parseFloat(project.TotalAmount).toFixed(2) | NumFormat }}</span>
                            <el-popover v-model="project.PopoveVisible">
                              <el-row>
                                <el-col :span="11" class="pad_10_0">
                                  <el-input size="small" type="number" v-model="project.PopoveAmount" placeholder="请输入金额" @input="savingCardPopInputChange(project, 0)">
                                    <template slot="prepend">¥</template>
                                  </el-input>
                                </el-col>
                                <el-col :span="2" class="text_center pad_10_0 martp_10">
                                  <i class="el-icon-sort"></i>
                                </el-col>
                                <el-col :span="11" class="pad_10_0">
                                  <el-input size="small" placeholder="请输入折扣" v-model="project.PerformanceRatio" @input="savingCardPopInputChange(project, 1)">
                                    <template slot="append">%</template>
                                  </el-input>
                                </el-col>
                              </el-row>
                              <el-row class="text_right martp_10">
                                <el-button size="small" @click="project.PopoveVisible = false">取消</el-button>
                                <el-button size="small" type="primary" @click="savingCardPopoverClickConfirm(project, item)">确定</el-button>
                              </el-row>

                              <el-button v-show="TreatPermission.ModifyPrices_TreatSavingCard" slot="reference" type="primary" icon="el-icon-edit" circle size="mini" @click="showPopover(project)"></el-button>
                            </el-popover>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeSavingCardSelectItemClick(index, pIndex)"></el-button>
                          </el-col>
                        </el-col>
                        <el-col :span="24">
                          <el-col :span="16" class="color_red">¥ {{ parseFloat(project.Price) | NumFormat }}</el-col>
                          <el-col :span="8">
                            <div>
                              <span class="color_gray font_12" v-if="project.PricePreferentialAmount != 0">
                                手动改价：
                                <span class="color_red" v-if="project.PricePreferentialAmount > 0">-{{ parseFloat(project.PricePreferentialAmount).toFixed(2) | NumFormat }}</span>
                                <span class="color_green" v-else>+{{ parseFloat(Math.abs(project.PricePreferentialAmount)).toFixed(2) | NumFormat }}</span>
                              </span>
                              <span class="color_gray font_12" :class="project.PricePreferentialAmount == 0 ? '' : 'marlt_15'" v-if="project.CardPreferentialAmount > 0">
                                卡优惠：
                                <span class="color_red">-{{ parseFloat(project.CardPreferentialAmountTotal) | NumFormat }}</span>
                              </span>
                            </div>
                          </el-col>
                        </el-col>
                      </el-col>
                      <el-col v-if="project.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                        <el-row class="cursor_pointer" @click.native="employeeHandleClick(2, project, pIndex)" v-for="(handleItem, handleIndex) in project.HandleTypeList" :key="handleIndex">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                                <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
              </div>
              <!--时效卡-->
              <div v-if="currentSelectTimeCardList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="2">时效卡</el-col>
                    <el-col :span="3">数量</el-col>
                    <el-col :span="3">当前体重(斤)</el-col>
                    <el-col :span="3">目标体重(斤)</el-col>
                    <el-col :span="3">目标减重(斤)</el-col>
                    <el-col :span="3">最轻体重(斤)</el-col>
                    <el-col :span="3">初始体重(斤)</el-col>
                    <el-col :span="2">金额</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row v-for="(item, index) in currentSelectTimeCardList" :key="index + 'st'">
                  <el-card shadow="never" style="border: 0px">
                    <div slot="header" class="font_13 cursor_pointer" @click="consumeTimeCardClick(item)">
                      <span>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                      </span>
                    </div>
                    <el-row v-for="(Project, ProjectIndex) in item.Projects" :key="ProjectIndex">
                      <el-col :span="24" class="pad_10 border_bottom">
                        <el-col :span="24">
                          <el-col :span="2">
                            <div>
                              {{ Project.Name }}
                              <span v-if="Project.Alias">({{ Project.Alias }})</span>
                              <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                            </div>
                          </el-col>
                          <el-col :span="3">
                            <el-input-number
                              v-model="Project.Quantity"
                              :min="1"
                              :max="getTimeCardMaxQuantity(item) - getTimeCardQuantity(item.ID) + Project.Quantity"
                              size="mini"
                              style="width: 100px"
                              @change="(oldval, newval) => timeItemQuantityChangeClick(oldval, newval, item, Project)"
                            ></el-input-number>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.CurrentWeight" :disabled="!Project.TargetWeight" size="mini" type="number" v-enter-number2 style="width: 60px" @change="(event) => changeCurrentWeight(Project, event)"> </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.CustomerTargetWeight" disabled size="mini" type="number" v-enter-number2 style="width: 60px"> </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.TargetWeight" disabled size="mini" type="number" v-enter-number2 style="width: 55px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.MinWeight" disabled size="mini" type="number" v-enter-number2 style="width: 55px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.SaleCurrentWeight" disabled size="mini" type="number" v-enter-number2 style="width: 55px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>

                          <el-col :span="2">
                            <div>{{ Project.TotalAmount | NumFormat }}</div>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="timeRemoveSelectItemClick(index, ProjectIndex)"></el-button>
                          </el-col>
                        </el-col>
                        <el-col :span="24">
                          <el-col :span="20">
                            <div class="color_red font_12">¥ {{ Project.Price | NumFormat }}</div>
                          </el-col>
                          <el-col :span="4">
                            <span class="color_gray font_12" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) != 0">
                              卡优惠：
                              <span class="color_green kayouhui" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) > 0">
                                +{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }}
                              </span>
                              <span class="color_red" v-else-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) < 0"> -{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                            </span>
                          </el-col>
                        </el-col>
                      </el-col>
                      <el-col v-if="Project.HandleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                        <el-row class="cursor_pointer" @click.native="employeeHandleClick(4, Project, ProjectIndex)" v-for="(handleItem, handleIndex) in Project.HandleTypeList" :key="handleIndex">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                                <el-input class="employee_num" v-model="empItem.Discount" size="mini" :disabled="Project.TargetWeight ? true : false" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
              </div>
              <!--通用次卡-->
              <div v-if="currentSelectGeneralCardList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">通用次卡</el-col>
                    <el-col :span="8">数量</el-col>
                    <el-col :span="7">金额</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row v-for="(item, index) in currentSelectGeneralCardList" :key="index + 'sg'">
                  <el-card shadow="never" style="border: 0px">
                    <div slot="header" class="font_13" @click="consumeGeneralCardClick(item.GeneralCardItem)">
                      <span>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                      </span>
                      <span class="color_gray" style="float: right">
                        <span>
                          <span>剩余次数：</span>
                          <span> {{ item.ValidBalance - getGeneralCardQuantity(item.ID) }}</span>
                        </span>
                        <span class="marlt_5">
                          <span>(有效次数：</span>
                          <span>{{ item.ValidBalance }}</span>
                          <span class="marlt_10">消耗次数：</span>
                          <span>{{ getGeneralCardQuantity(item.ID) }})</span>
                        </span>
                      </span>
                    </div>
                    <el-row v-for="(Project, ProjectIndex) in item.Projects" :key="ProjectIndex">
                      <el-col :span="24" class="pad_10 border_bottom">
                        <el-col :span="24">
                          <el-col :span="8">
                            <div>
                              {{ Project.Name }}
                              <span v-if="Project.Alias">({{ Project.Alias }})</span>
                              <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                            </div>
                          </el-col>
                          <!-- :max="Math.floor((item.ValidBalance - getGeneralCardQuantity(item.ID))/Project.ConsumeAmount)  + Project.Quantity" -->
                          <el-col :span="8">
                            <el-input-number v-model="Project.Quantity" :min="1" :max="getGeneralCardProjectMaxQuantity(item, Project)" size="mini" style="width: 100px" @change="(oldval, newval) => generalItemQuantityChangeClick(oldval, newval, item, Project)"></el-input-number>
                          </el-col>
                          <el-col :span="7">
                            <div>
                              {{ Number(Project.TotalAmount).toFixed(2) }}
                            </div>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeSelectGeneralItemClick(index, ProjectIndex)"></el-button>
                          </el-col>
                        </el-col>
                        <el-col :span="24">
                          <el-col :span="16">
                            <div>
                              <span class="color_red">¥ {{ Project.Price | NumFormat }}</span>
                              <span class="color_gray marlt_5 font_12">
                                (本次耗卡次数：{{ Project.ConsumeAmount * Project.Quantity }} <span class="marlt_10">单次耗卡次数: {{ Project.ConsumeAmount }} 次</span>)
                              </span>
                            </div>
                          </el-col>
                          <el-col :span="8">
                            <span class="color_gray font_12" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) != 0">
                              卡优惠：
                              <span class="color_green" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) > 0"> +{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                              <span class="color_red" v-else-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) < 0"> -{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                            </span>
                          </el-col>
                        </el-col>
                      </el-col>
                      <el-col v-if="Project.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                        <el-row class="cursor_pointer" @click.native="employeeHandleClick(5, Project, ProjectIndex)" v-for="(handleItem, handleIndex) in Project.HandleTypeList" :key="handleIndex">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                                <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
              </div>
              <!--产品-->
              <div v-if="currentSelectProductList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">产品</el-col>
                    <el-col :span="8">数量</el-col>
                    <el-col :span="8">金额</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in currentSelectProductList" :key="index + 'spd'">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="8">
                      <div>
                        {{ item.Name }}
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                      </div>
                      <div class="color_red martp_5">
                        <span>¥ {{ item.Price | NumFormat }}</span>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <el-input-number v-model="item.Quantity" :min="1" :max="item.ValidBalance - getCurrentProductQuantity(item.ID) + item.Quantity" size="mini" style="width: 100px" @change="productItemQuantityChangeClick(item)"></el-input-number>
                    </el-col>
                    <el-col :span="7">
                      <div>
                        <span class="marrt_15">¥ {{ parseFloat(item.TotalAmount).toFixed(2) | NumFormat }}</span>
                      </div>
                      <div class="martp_5">
                        <span class="color_gray font_12" v-if="parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity) != 0">
                          卡优惠：
                          <span class="color_green" v-if="parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity) > 0"> +{{ Math.abs(parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity)).toFixed(2) | NumFormat }} </span>
                          <span class="color_red" v-else-if="parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity) < 0"> -{{ Math.abs(parseFloat(item.TotalAmount) - parseFloat(item.Price) * parseFloat(item.Quantity)).toFixed(2) | NumFormat }} </span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="1">
                      <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeSelectProductItemClick(index)"></el-button>
                    </el-col>
                  </el-col>
                  <el-col v-if="item.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" @click.native="employeeHandleClick(1, item, index)" v-for="(handleItem, handleIndex) in item.HandleTypeList" :key="handleIndex">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                            <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--套餐卡-->
              <div v-for="(item, index) in currentselectPackageCardList" :key="index">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="2">
                      <span>套餐卡-{{ item.Name }}</span>
                      <span v-if="item.Alias">({{ item.Alias }})</span>
                      <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                    </el-col>
                    <el-col :span="3">数量</el-col>
                    <el-col :span="3">当前体重(斤)</el-col>
                    <el-col :span="3">目标体重(斤)</el-col>
                    <el-col :span="3">目标减重(斤)</el-col>
                    <el-col :span="3">最轻体重(斤)</el-col>
                    <el-col :span="3">初始体重(斤)</el-col>
                    <el-col :span="2">金额</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <!-- 套餐卡项目 -->
                <el-row class="border_bottom" v-for="(Project, ProjectIndex) in item.packageProjectList" :key="ProjectIndex + 'sppr'">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="2">
                      <div>
                        <el-tag size="mini">项目</el-tag>
                        <span>{{ Project.Name }}</span>
                        <span v-if="Project.Alias">({{ Project.Alias }})</span>
                        <el-tag class="marlt_5" v-if="Project.IsLargess" size="mini" type="danger">赠</el-tag>
                      </div>
                      <div class="color_red martp_5">
                        <span>¥ {{ Project.Price | NumFormat }}</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <el-input-number v-model="Project.Quantity" :min="1" :max="Project.ValidBalance - getPackageProjectConsumeQuantity(item, Project.ID) + Project.Quantity" size="mini" style="width: 100px" @change="(oldval, newval) => packageProjectQuantityChangeClick(Project)"></el-input-number>
                    </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="2">
                      <div>
                        <span class="marrt_15">¥ {{ Project.TotalAmount | NumFormat }}</span>
                      </div>
                      <div>
                        <span class="color_gray font_12" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) != 0">
                          卡优惠：
                          <span class="color_green" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) > 0"> +{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                          <span class="color_red" v-else-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) < 0"> -{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="1">
                      <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageProjectItemClick(item, index, childIndex)"></el-button>
                    </el-col>
                  </el-col>
                  <el-col v-if="Project.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" @click.native="employeeHandleClick(0, Project)" v-for="(handleItem, handleIndex) in Project.HandleTypeList" :key="handleIndex">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                            <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <!-- 套餐卡储值卡 -->
                <el-row v-for="(SavingCard, SavingCardIndex) in item.packageSavingCardList" :key="SavingCardIndex + 'sps'">
                  <el-card shadow="never" style="border: 0px">
                    <div slot="header" class="font_13 cursor_pointer" @click="consumePackageSavingCardSelectClick(item, SavingCard)">
                      <span>
                        <el-tag size="mini">储值卡</el-tag>
                        <span>{{ SavingCard.Name }}</span>
                        <span v-if="SavingCard.Alias">({{ SavingCard.Alias }})</span>
                      </span>
                      <span class="color_gray" style="float: right">
                        <span>
                          <span>剩余金额：</span>
                          <span>¥ {{ parseFloat(SavingCard.ValidBalance - getPackageSavingCardConsumeAmount(item, SavingCard.ID)).toFixed(2) | NumFormat }}</span>
                        </span>
                        <span class="marlt_5">
                          <span>(卡余额：</span>
                          <span>¥ {{ parseFloat(SavingCard.ValidBalance).toFixed(2) | NumFormat }}</span>
                          <span class="marlt_10">卡抵扣：</span>
                          <span>-¥ {{ parseFloat(getPackageSavingCardConsumeAmount(item, SavingCard.ID)).toFixed(2) | NumFormat }})</span>
                        </span>
                      </span>
                    </div>
                    <el-row v-for="(Project, ProjectIndex) in SavingCard.Projects" :key="ProjectIndex">
                      <el-col :span="24" class="pad_10 border_bottom">
                        <el-col :span="24">
                          <el-col :span="2">
                            <div>
                              {{ Project.Name }}
                              <span v-if="Project.Alias">({{ Project.Alias }})</span>
                            </div>
                          </el-col>
                          <el-col :span="3">
                            <el-input-number v-model="Project.Quantity" :min="1" size="mini" style="width: 100px" @change="(oldval, newval) => packageSavingCartItemQuantityChangeClick(oldval, newval, item, SavingCard, Project)"></el-input-number>
                          </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="2">
                            <span class="marrt_15">{{ parseFloat(Project.TotalAmount).toFixed(2) | NumFormat }}</span>
                            <el-popover v-model="Project.PopoveVisible">
                              <el-row>
                                <el-col :span="11" class="pad_10_0">
                                  <el-input size="small" type="number" v-model="Project.PopoveAmount" placeholder="请输入金额" @input="savingCardPopInputChange(Project, 0)">
                                    <template slot="prepend">¥</template>
                                  </el-input>
                                </el-col>
                                <el-col :span="2" class="text_center pad_10_0 martp_10">
                                  <i class="el-icon-sort"></i>
                                </el-col>
                                <el-col :span="11" class="pad_10_0">
                                  <el-input size="small" placeholder="请输入折扣" v-model="Project.PerformanceRatio" @input="savingCardPopInputChange(Project, 1)">
                                    <template slot="append">%</template>
                                  </el-input>
                                </el-col>
                              </el-row>
                              <el-row class="text_right martp_10">
                                <el-button size="small" @click="Project.PopoveVisible = false">取消</el-button>
                                <el-button size="small" type="primary" @click="savingCardPopoverClickConfirm(Project, SavingCard)">确定</el-button>
                              </el-row>

                              <el-button v-show="TreatPermission.ModifyPrices_TreatSavingCard" slot="reference" type="primary" icon="el-icon-edit" circle size="mini" @click="showPopover(Project)"></el-button>
                            </el-popover>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageSavingCardItemClick(item, SavingCard, Project, index, SavingCardIndex, ProjectIndex)"></el-button>
                          </el-col>
                        </el-col>
                        <el-col :span="24">
                          <el-col :span="16" class="color_red">¥ {{ parseFloat(Project.Price).toFixed(2) | NumFormat }}</el-col>
                          <el-col :span="8">
                            <div>
                              <span class="color_gray font_12" v-if="Project.PricePreferentialAmount != 0">
                                手动改价：
                                <span class="color_red" v-if="Project.PricePreferentialAmount > 0">-{{ parseFloat(Project.PricePreferentialAmount).toFixed(2) | NumFormat }}</span>
                                <span class="color_green" v-else>+{{ parseFloat(Math.abs(Project.PricePreferentialAmount)).toFixed(2) | NumFormat }}</span>
                              </span>
                              <span class="color_gray font_12" :class="Project.PricePreferentialAmount == 0 ? '' : 'marlt_15'" v-if="Project.CardPreferentialAmount > 0">
                                卡优惠：
                                <span class="color_red">-{{ parseFloat(Project.CardPreferentialAmountTotal) | NumFormat }}</span>
                              </span>
                            </div>
                          </el-col>
                        </el-col>
                      </el-col>
                      <el-col v-if="Project.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                        <el-row class="cursor_pointer" @click.native="employeeHandleClick(2, Project)" v-for="(handleItem, handleIndex) in Project.HandleTypeList" :key="handleIndex">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                                <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
                <!-- 套餐卡时效卡 -->
                <el-row v-for="(timeCard, timeCardIndex) in item.packageTimeCardList" :key="timeCardIndex + 'spt'">
                  <el-card shadow="never" style="border: 0px">
                    <div slot="header" class="font_13 cursor_pointer" @click="consumePackageTimeCardSelectClick(item, timeCard)">
                      <span>
                        <el-tag size="mini">时效卡</el-tag>
                        <span>{{ timeCard.Name }}</span>
                        <span v-if="timeCard.Alias">({{ timeCard.Alias }})</span>
                        <el-tag class="marlt_5" v-if="timeCard.IsLargess" size="mini" type="danger">赠</el-tag>
                      </span>
                    </div>
                    <el-row v-for="(Project, ProjectIndex) in timeCard.Projects" :key="ProjectIndex">
                      <el-col :span="24" class="pad_10 border_bottom">
                        <el-col :span="24">
                          <el-col :span="2">
                            <div>
                              {{ Project.Name }}
                              <span v-if="Project.Alias">({{ Project.Alias }})</span>
                              <el-tag class="marlt_5" v-if="item.IsLargess" size="mini" type="danger">赠</el-tag>
                            </div>
                          </el-col>
                          <el-col :span="3">
                            <el-input-number
                              v-model="Project.Quantity"
                              :min="1"
                              :max="getTimeCardMaxQuantity(timeCard) - getPackageTimeCardConsumAmount(item.ID, timeCard.ID) + Project.Quantity"
                              size="mini"
                              style="width: 100px"
                              @change="(oldval, newval) => packageTimeCartItemQuantityChangeClick(oldval, newval, item, timeCard, Project)"
                            ></el-input-number>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.CurrentWeight" :disabled="!Project.TargetWeight" size="mini" type="number" v-enter-number2 style="width: 60px" @change="(event) => changeCurrentWeight(Project, event)">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.CustomerTargetWeight" disabled size="mini" type="number" v-enter-number2 style="width: 60px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.TargetWeight" disabled size="mini" type="number" v-enter-number2 style="width: 55px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.MinWeight" disabled size="mini" type="number" v-enter-number2 style="width: 55px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="3">
                            <el-input v-model="Project.SaleCurrentWeight" disabled size="mini" type="number" v-enter-number2 style="width: 55px">
                              <!-- <template slot="append">斤</template> -->
                            </el-input>
                          </el-col>
                          <el-col :span="2">
                            <span>{{ Project.TotalAmount | NumFormat }}</span>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageTimeCardItemClick(item, timeCard, Project, index, timeCardIndex, ProjectIndex)"></el-button>
                          </el-col>
                        </el-col>
                        <el-col :span="24">
                          <el-col :span="19">
                            <div class="color_red">¥ {{ Project.Price | NumFormat }}</div>
                          </el-col>
                          <el-col :span="5">
                            <span class="color_gray font_12" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) != 0">
                              卡优惠：
                              <span class="color_green" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) > 0"> +{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                              <span class="color_red" v-else-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) < 0"> -{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                            </span>
                          </el-col>
                        </el-col>
                      </el-col>
                      <el-col v-if="Project.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                        <el-row class="cursor_pointer" @click.native="employeeHandleClick(4, Project)" v-for="(handleItem, handleIndex) in Project.HandleTypeList" :key="handleIndex">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                                <el-input class="employee_num" v-model="empItem.Discount" :disabled="Project.TargetWeight ? true : false" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
                <!-- 套餐卡通用次卡 -->
                <el-row v-for="(generalCard, generalCardIndex) in item.packageGeneralList" :key="generalCardIndex + 'spg'">
                  <el-card shadow="never" style="border: 0px">
                    <div slot="header" class="font_13 cursor_pointer" @click="consumePackageGeneralCardSelectClick(item, generalCard)">
                      <span>
                        <el-tag size="mini">通用次卡</el-tag>
                        <span>{{ generalCard.Name }}</span>
                        <span v-if="generalCard.Alias">({{ generalCard.Alias }})</span>
                        <el-tag class="marlt_5" v-if="generalCard.IsLargess" size="mini" type="danger">赠</el-tag>
                      </span>
                      <span class="color_gray" style="float: right">
                        <span>
                          <span>剩余次数：</span>
                          <span>¥ {{ generalCard.ValidBalance - getPackageGeneralCardConsumAmount(item.ID, generalCard.ID) }}</span>
                        </span>
                        <span class="marlt_5">
                          <span>(有效次数：</span>
                          <span>{{ generalCard.ValidBalance }}</span>
                          <span class="marlt_10">消耗次数：</span>
                          <span>{{ getPackageGeneralCardConsumAmount(item.ID, generalCard.ID) }})</span>
                        </span>
                      </span>
                    </div>
                    <el-row v-for="(Project, ProjectIndex) in generalCard.Projects" :key="ProjectIndex">
                      <el-col :span="24" class="pad_10 border_bottom">
                        <el-col :span="24">
                          <el-col :span="2">
                            <div>
                              {{ Project.Name }}
                              <span v-if="Project.Alias">({{ Project.Alias }})</span>
                              <el-tag class="marlt_5" v-if="generalCard.IsLargess" size="mini" type="danger">赠</el-tag>
                            </div>
                          </el-col>
                          <el-col :span="3">
                            <el-input-number
                              v-model="Project.Quantity"
                              :min="1"
                              :max="getPackageGeneralCardProjectMaxQuantity(item, generalCard, Project)"
                              size="mini"
                              style="width: 100px"
                              @change="(oldval, newval) => packageGeneralCartItemQuantityChangeClick(oldval, newval, item, generalCard, Project)"
                            ></el-input-number>
                          </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="3"> - </el-col>
                          <el-col :span="2">
                            <span>{{ parseFloat(Project.TotalAmount).toFixed(2) | NumFormat }}</span>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageGeneralCardItemClick(item, generalCard, Project, index, generalCardIndex, ProjectIndex)"></el-button>
                          </el-col>
                        </el-col>
                        <el-col :span="24">
                          <el-col :span="24">
                            <el-col :span="16">
                              <div>
                                <span class="color_red">¥ {{ Project.Price | NumFormat }}</span>
                                <span class="color_gray marlt_5 font_12">
                                  (本次耗卡次数：{{ Project.ConsumeAmount * Project.Quantity }} <span class="marlt_10">单次耗卡次数: {{ Project.ConsumeAmount }} 次</span>)
                                </span>
                              </div>
                            </el-col>
                            <el-col :span="8">
                              <span class="color_gray font_12" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) != 0">
                                卡优惠：
                                <span class="color_green" v-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) > 0"> +{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }} </span>
                                <span class="color_red" v-else-if="parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity) < 0">
                                  -{{ Math.abs(parseFloat(Project.TotalAmount) - parseFloat(Project.Price) * parseFloat(Project.Quantity)).toFixed(2) | NumFormat }}
                                </span>
                              </span>
                            </el-col>
                          </el-col>
                        </el-col>
                      </el-col>
                      <el-col v-if="Project.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                        <el-row class="cursor_pointer" @click.native="employeeHandleClick(5, Project)" v-for="(handleItem, handleIndex) in Project.HandleTypeList" :key="handleIndex">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini">
                              <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                                <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-row>
                <!-- 套餐卡产品 -->
                <el-row class="border_bottom" v-for="(Product, ProductIndex) in item.packageProductList" :key="ProductIndex + 'sppd'">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="2">
                      <div>
                        <el-tag size="mini">产品</el-tag>
                        <span>{{ Product.Name }}</span>
                        <span v-if="Product.Alias">({{ Product.Alias }})</span>
                        <el-tag class="marlt_5" v-if="Product.IsLargess" size="mini" type="danger">赠</el-tag>
                      </div>
                      <div class="color_red martp_5">
                        <span>¥ {{ Product.Price | NumFormat }}</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <el-input-number v-model="Product.Quantity" :min="1" :max="Product.ValidBalance - getPackageProductConsumeQuantity(item, Product.ID) + Product.Quantity" size="mini" style="width: 100px" @change="(oldval, newval) => packageProductQuantityChangeClick(Product)"></el-input-number>
                    </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="3"> - </el-col>
                    <el-col :span="2">
                      <div>
                        <span class="marrt_15">¥ {{ Product.TotalAmount | NumFormat }}</span>
                      </div>
                      <div>
                        <span class="color_gray font_12" v-if="parseFloat(Product.TotalAmount) - parseFloat(Product.Price) * parseFloat(Product.Quantity) != 0">
                          卡优惠：
                          <span class="color_green" v-if="parseFloat(Product.TotalAmount) - parseFloat(Product.Price) * parseFloat(Product.Quantity) > 0"> +{{ Math.abs(parseFloat(Product.TotalAmount) - parseFloat(Product.Price) * parseFloat(Product.Quantity)).toFixed(2) | NumFormat }} </span>
                          <span class="color_red" v-else-if="parseFloat(Product.TotalAmount) - parseFloat(Product.Price) * parseFloat(Product.Quantity) < 0"> -{{ Math.abs(parseFloat(Product.TotalAmount) - parseFloat(Product.Price) * parseFloat(Product.Quantity)).toFixed(2) | NumFormat }} </span>
                        </span>
                      </div>
                    </el-col>
                    <el-col :span="1">
                      <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageProductItemClick(item, index, childIndex)"></el-button>
                    </el-col>
                  </el-col>
                  <el-col v-if="Product.HandleTypeList.length > 0" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" @click.native="employeeHandleClick(1, Product)" v-for="(handleItem, handleIndex) in Product.HandleTypeList" :key="handleIndex">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handleItem.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(empItem, empIndex) in handleItem.Employee" :key="empIndex" :label="`${empItem.EmployeeName} `">
                            <el-input class="employee_num" v-model="empItem.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handleItem.Employee, empItem)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handleItem, empIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-scrollbar>
          </el-main>
          <el-footer class="border_top">
            <el-row class="color_333" type="flex" align="middle">
              <el-col :span="6">
                <span>
                  <span class="font_14 color_maroon">消耗金额(含赠送)：¥ {{ Number(totalAmount).toFixed(2) | NumFormat }}</span>
                </span>
              </el-col>
              <el-col :span="15">
                <el-input type="textarea" :rows="1" placeholder="请输入备注信息" v-model="remark"></el-input>
              </el-col>
              <el-col :span="3" class="text_right">
                <el-button type="primary" size="small" @click="consumeBillClick">结账</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>

    <!-- 储值卡消耗项目 -->
    <el-dialog :visible.sync="dialogSavingCard" :title="selectSavingcardItem.Name" width="680px">
      <div slot="title">
        储值卡消耗 - {{ selectSavingcardItem.Name }}
        <span v-if="selectSavingcardItem.Alias">({{ selectSavingcardItem.Alias }})</span>
      </div>
      <el-input @clear="clearAndSearch" placeholder="请输入项目名称、别名关键字，按回车搜索" prefix-icon="el-icon-search" v-model="searchSavingcardProjectName" clearable size="small" @keyup.enter.native="searchSavingCardProjectClick"></el-input>
      <el-row class="valueCard_project border martp_10">
        <el-col :span="6" class="border_right text_center category">
          <!-- <div class="row_header">分类</div> -->
          <el-scrollbar class="el-scrollbar_height el-scrollbar_dialog_height">
            <div v-for="(cItem, index) in currentSavingcardProjectCategoty" :key="index + 'sav_c'" :class="[currentCategoryIndex == index ? 'category_select' : '', 'pad_10_15', 'border_bottom', 'cursor_pointer']" @click="savingCardProjectCategoryClick(cItem, index)">
              {{ cItem.Name }}
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="18" class="category">
          <!-- <div class="row_header">列表</div> -->
          <el-scrollbar class="el-scrollbar_height">
            <el-collapse v-model="currentSavingActiveName">
              <el-collapse-item v-for="(item, index) in currentSavingcardProject" :key="index + 'sav_c_1'" :title="item.Name" :name="item.ID">
                <div v-for="project in item.Child" class="border_bottom pad_5_10 cursor_pointer" :key="project.ID + 'sva_p'" @click="addSavingCardApplyProjectItem(project)">
                  <div>
                    {{ project.Name }}
                    <span v-if="project.Alias">({{ project.Alias }})</span>
                  </div>
                  <div class="font_13">
                    <span class="color_red font_13">¥ {{ project.PreferentialPrice | NumFormat }}</span>
                    <span class="color_gray font_10 marlt_5">
                      <span>(原价：</span>
                      <span class="t-through">¥ {{ project.Price | NumFormat }}</span>
                      <span class="marlt_10" v-if="project.PriceType == 1">卡权益：{{ project.discountName }})</span>
                      <span class="marlt_10" v-if="project.PriceType == 2">卡权益：¥ {{ project.DiscountPrice | NumFormat }} 元)</span>
                    </span>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-scrollbar>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-row type="flex" align="middle">
          <el-col :span="18" class="text_left font_14">
            <div class="font_14">
              <span>
                <span>剩余金额：</span>
                <span class="color_red">¥ {{ (selectSavingcardItem.ValidBalance - getCurrentSavingCardConsumeAmount(savingCardConsumeItem, isConsumePackage)) | NumFormat }}</span>
              </span>
              <span class="font_12 color_gray marlt_5">
                <span>(可用余额：</span>
                <span>¥ {{ selectSavingcardItem.ValidBalance | NumFormat }}</span>
                <span class="marlt_10">卡抵扣：</span>
                <span>-¥ {{ getCurrentSavingCardConsumeAmount(savingCardConsumeItem, isConsumePackage) | NumFormat }})</span>
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click="dialogSavingCard = false" size="small">取消</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>

    <!-- 通用次卡消耗项目 -->
    <el-dialog :visible.sync="dialogGeneralCard" width="680px">
      <div slot="title">
        通用次卡消耗 - {{ selectGeneralcardItem.Name }}
        <span v-if="selectGeneralcardItem.Alias">({{ selectGeneralcardItem.Alias }})</span>
      </div>
      <el-input placeholder="请输入项目名称、别名关键字，按回车搜索" prefix-icon="el-icon-search" v-model="searchGeneralcarPorjectName" clearable size="small" @clear="clearAndGenterSearch" @keyup.enter.native="searchGeneralCardProjectClick"></el-input>
      <el-row class="valueCard_project border martp_10">
        <el-col :span="6" class="border_right text_center category">
          <!-- <div class="row_header">分类</div> -->
          <el-scrollbar class="el-scrollbar_height">
            <div v-for="(cateItem, index) in currentGeneralcarProjectCategoty" :key="index" :class="[currentCategoryIndex == index ? 'category_select' : '', 'pad_10_15', 'border_bottom', 'cursor_pointer']" @click="generalCardProjectCategoryClick(cateItem, index)">
              {{ cateItem.Name }}
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="18" class="category">
          <!-- <div class="row_header">列表</div> -->
          <el-scrollbar class="el-scrollbar_height">
            <el-collapse v-model="currentGeneralcarActiveName">
              <el-collapse-item v-for="(item, index) in currentGeneralcarProject" :key="index" :title="item.Name" :name="item.ID">
                <div v-for="project in item.Child" class="border_bottom pad_5_10" :key="project.ID" @click="addGeneralclickCardApplyProjectItem(project)">
                  <div>
                    {{ project.Name }}
                    <span v-if="project.Alias">({{ project.Alias }})</span>
                  </div>
                  <div class="dis_flex flex_x_between">
                    <span class="color_red font_13">¥ {{ project.Price | NumFormat }}</span>
                    <!-- <span class="color_gray t-through font_10"></span> -->
                    <div class="color_gray font_10">单次消耗卡：{{ project.ConsumeAmount }}次</div>
                  </div>
                  <!-- <div class="font_13">
                    <span class="color_red font_13">¥ {{project.Price | NumFormat}}</span>
                    <span class="color_gray font_10 marlt_5">
                      <span>(单次消耗卡{{project.ConsumeAmount}}次)</span>
                    </span>
                  </div>-->
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-scrollbar>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-row type="flex" align="middle">
          <el-col :span="18" class="text_left font_14">
            <div class="font_14">
              <span>
                <span>剩余次数：</span>
                <span v-if="isConsumePackage" class="color_red">{{ selectGeneralcardItem.ValidBalance - getPackageGeneralCardConsumAmount(tempCurrentSelectPackageItem.ID, selectGeneralcardItem.ID) }}</span>
                <span v-else class="color_red">{{ selectGeneralcardItem.ValidBalance - getGeneralCardQuantity(selectGeneralcardItem.ID) }}</span>
              </span>
              <span class="font_12 color_gray marlt_5">
                <span>(有效次数：</span>
                <span>{{ selectGeneralcardItem.ValidBalance }}</span>
                <span class="marlt_10">消耗次数：</span>
                <span>{{ getGeneralCardQuantity(selectGeneralcardItem.ID) }})</span>
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click="dialogGeneralCard = false" size="small">取消</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>

    <!-- 时效卡消耗项目 -->
    <el-dialog :visible.sync="dialogTimeCard" width="680px">
      <div slot="title">
        时效卡消耗 - {{ selectTimecardItem.Name }}
        <span v-if="selectTimecardItem.Alias">({{ selectTimecardItem.Alias }})</span>
      </div>
      <el-input placeholder="请输入项目名称、别名关键字，按回车搜索" prefix-icon="el-icon-search" v-model="searchTimeCardPorjectName" clearable size="small" asdf @clear="clearAndTimeSearch" @keyup.enter.native="searchTimeCardProjectClick">
        <!-- <el-button slot="append" icon="el-icon-search" @click="searchTimeCardProjectClick"></el-button> -->
      </el-input>
      <el-row class="valueCard_project border martp_10">
        <el-col :span="6" class="border_right text_center category">
          <!-- <div class="row_header">分类</div> -->
          <el-scrollbar class="el-scrollbar_height">
            <div v-for="(cateItem, index) in currentTimeCardProjectCategoty" :key="index + 'time_p_'" :class="[currentCategoryIndex == index ? 'category_select' : '', 'pad_10_15', 'border_bottom', 'cursor_pointer', 'font_13']" @click="timeCardProjectCategoryClick(cateItem, index)">
              {{ cateItem.Name }}
            </div>
          </el-scrollbar>
        </el-col>
        <el-col :span="18" class="category">
          <!-- <div class="row_header">列表</div> -->
          <el-scrollbar class="el-scrollbar_height">
            <el-collapse v-model="currentTimeCardActiveName">
              <el-collapse-item v-for="(item, index) in currentTimeCardProject" :key="index + 'time_active'" :name="item.ID">
                <template slot="title">
                  <span class="font_13">{{ item.Name }}</span>
                </template>

                <div v-for="project in item.Child" class="border_bottom pad_5_10 font_13" :key="project.ID" @click="addTimeCardApplyProjectItem(project)">
                  <div>
                    {{ project.Name }}
                    <span v-if="project.Alias">({{ project.Alias }})</span>
                  </div>
                  <div>
                    <span class="color_red font_13">¥ {{ project.Price | NumFormat }}</span>
                    <!-- <span class="color_gray t-through"></span>
                    <div class="color_gray"></div>-->
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-scrollbar>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button type="primary" plain @click="dialogTimeCard = false" size="small">取消</el-button>
      </span>
    </el-dialog>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="dialogVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler, index) in treatHandlerList" :key="index">
            <el-row style="max-height: 300px; overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter((item) => !handlerName || item.EmployeeName.toLowerCase().includes(handlerName.toLowerCase()) || item.EmployeeID.toLowerCase().includes(handlerName.toLowerCase()))" :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee, item)">
                  <span class="marrt_10">{{ item.EmployeeName }} [{{ item.EmployeeID }}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" :disabled="itemList.TargetWeight ? true : false" style="width: 120px" type="number" size="mini" min="0" max="100" v-enter-number @input="handlerPercentChange(handler.Employee, item)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick">确 定</el-button>
      </div>
    </el-dialog>

    <!--结账-->
    <el-dialog :visible.sync="dialogBill" width="350px">
      <div class="dis_flex flex_x_center flex_y_center flex_dir_column">
        <el-row>
          <i class="el-icon-document" style="font-size: 80px; color: #999"></i>
        </el-row>
        <el-row>
          <el-col class="color_red font_24 martp_15">是否确认结账</el-col>
        </el-row>
        <el-row>
          <el-col class="martp_15">是否确认单据无误，确认后系统将生成单据</el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBill = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitConsumeOrderClick" size="small">确定结账</el-button>
      </div>
    </el-dialog>

    <!--结账成功-->
    <el-dialog :visible.sync="dialoConsumeSucceed" width="450px">
      <div class="text_center pad_15">
        <i class="el-icon-document" style="font-size: 80px; color: #999"></i>
        <div class="pad_15 color_primary font_weight_600 font_18">
          订单已结账成功
        </div>
      </div>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">订单号：</el-col>
        <el-col :span="12" class="text_right">{{ consumeBillNumber }}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">订单金额：</el-col>
        <el-col :span="12" class="color_red text_right">¥{{ consumeTotalAmount | NumFormat }}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="5">订单备注：</el-col>
        <el-col :span="19">{{ remark }}</el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="continueCreateConsumeOrder" size="small">继续开单</el-button>
        <el-button type="primary" @click="showTreatInfo" :loading="printLoading" size="small">打印小票</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 消耗订单 -->
    <el-dialog :visible.sync="treatCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="treatInfo && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height: 500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height: 32px">{{ userInfo.EntityName }}</span>
                </div>
                <el-divider><span class="font_12 color_gray">消耗信息</span></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex: 3">{{ treatInfo.ID }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex: 3">{{ treatInfo.BillDate }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ cashierReceipt.NameEncrypt ? formatName(treatInfo.Name) : treatInfo.Name }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ cashierReceipt.MobileEncrypt ? formatPhone(treatInfo.PhoneNumber) : treatInfo.PhoneNumber }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ treatInfo.EmployeeName }}</span>
                </div>
                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ treatInfo.AddressDetail }}</span>
                </div>
                <el-divider><span class="font_12 color_gray">消耗明细</span></el-divider>

                <template v-for="(item, index) in treatInfo.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 }} {{ item.ProjectName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>

                    <div class="dis_flex">
                      <span
                        class="
                          font_12
                          color_gray
                          text-left
                          line_height_24
                          marlt_10
                        "
                        style="flex: 2"
                        >数量</span
                      >
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div v-if="cashierReceipt.TreatPromotions && item.CardPreferentialAmount != 0" class="dis_flex">
                      <span
                        class="
                          font_12
                          color_gray
                          text-left
                          line_height_24
                          marlt_10
                        "
                        style="flex: 2"
                        >卡优惠</span
                      >
                      <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.CardPreferentialAmount | NumFormat}}</span> -->
                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount < 0">¥ {{ Math.abs(item.CardPreferentialAmount) | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount > 0">¥ -{{ Math.abs(item.CardPreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="
                          font_12
                          color_gray
                          text-left
                          line_height_24
                          marlt_10
                        "
                        style="flex: 2"
                        >小计</span
                      >
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in treatInfo.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div v-for="(Project, pIndex) in item.Project" :key="pIndex + item.SavingCardID + Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                          {{ +treatInfo.Project.length + getCurrentCardLength(treatInfo.SavingCard, index, pIndex) }}
                          {{ Project.ProjectName }}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ Project.Price | NumFormat }}</span>
                      </div>

                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >数量</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">{{ Project.Quantity }}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions && Project.CardPreferentialAmount != 0" class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >卡优惠</span
                        >
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount < 0">¥ {{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount > 0">¥ -{{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                      </div>

                      <div v-if="cashierReceipt.TreatPromotions && Project.PricePreferentialAmount != 0" class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >手动改价</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">¥ {{ Project.PricePreferentialAmount | NumFormat }}</span>
                      </div>
                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >小计</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ Project.TotalAmount | NumFormat }}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in treatInfo.TimeCard">
                  <div :key="index + 'TimeCard' + item.TimeCardID">
                    <div v-for="(Project, pIndex) in item.Project" :key="pIndex + '' + item.TimeCardID + '' + Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                          {{ treatInfo.Project.length + getCardTotalLength(treatInfo.SavingCard) + getCurrentCardLength(treatInfo.TimeCard, index, pIndex) }}
                          {{ Project.ProjectName }}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ Project.Price | NumFormat }}</span>
                      </div>

                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >数量</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">{{ Project.Quantity }}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions && Project.CardPreferentialAmount != 0" class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >卡优惠</span
                        >
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount < 0">¥ {{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount > 0">¥ -{{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                      </div>
                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >小计</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ Project.TotalAmount | NumFormat }}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in treatInfo.GeneralCard">
                  <div :key="index + 'GeneralCard' + item.GeneralCardID">
                    <div v-for="(Project, pIndex) in item.Project" :key="pIndex + item.SavingCardID + Project.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                          {{ treatInfo.Project.length + getCardTotalLength(treatInfo.SavingCard) + getCardTotalLength(treatInfo.TimeCard) + getCurrentCardLength(treatInfo.GeneralCard, index, pIndex) }}
                          {{ Project.ProjectName }}
                          <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ Project.Price | NumFormat }}</span>
                      </div>

                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >数量</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">{{ Project.Quantity }}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions && item.CardPreferentialAmount != 0" class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >卡优惠</span
                        >
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount < 0">¥ {{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                        <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount > 0">¥ -{{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                      </div>
                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >小计</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ Project.TotalAmount | NumFormat }}</span>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in treatInfo.Product">
                  <div :key="index + 'Product' + item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + treatInfo.Project.length + getCardTotalLength(treatInfo.SavingCard) + getCardTotalLength(treatInfo.TimeCard) + getCardTotalLength(treatInfo.GeneralCard) }}
                        {{ item.ProductName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>

                    <div class="dis_flex">
                      <span
                        class="
                          font_12
                          color_gray
                          text-left
                          line_height_24
                          marlt_10
                        "
                        style="flex: 2"
                        >数量</span
                      >
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div v-if="cashierReceipt.TreatPromotions && item.CardPreferentialAmount != 0" class="dis_flex">
                      <span
                        class="
                          font_12
                          color_gray
                          text-left
                          line_height_24
                          marlt_10
                        "
                        style="flex: 2"
                        >卡优惠</span
                      >
                      <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.CardPreferentialAmount | NumFormat}}</span> -->

                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount < 0">¥ {{ Math.abs(item.CardPreferentialAmount) | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" v-if="item.CardPreferentialAmount > 0">¥ -{{ Math.abs(item.CardPreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="
                          font_12
                          color_gray
                          text-left
                          line_height_24
                          marlt_10
                        "
                        style="flex: 2"
                        >小计</span
                      >
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in treatInfo.PackageCard">
                  <div :key="index + 'PackageCard' + item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem, Pindex) in item.Project" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                          {{ Pindex + 1 + treatInfo.Project.length + getCardTotalLength(treatInfo.SavingCard) + getCardTotalLength(treatInfo.TimeCard) + getCardTotalLength(treatInfo.GeneralCard) + treatInfo.Product.length }}
                          {{ packItem.ProjectName }}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ packItem.Price | NumFormat }}</span>
                      </div>

                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >数量</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">{{ packItem.Quantity }}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions && packItem.CardPreferentialAmount != 0" class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >卡优惠</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">¥ {{ packItem.CardPreferentialAmount | NumFormat }}</span>
                      </div>
                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >小计</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ packItem.TotalAmount | NumFormat }}</span>
                      </div>
                    </div>
                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem, Pindex) in item.SavingCard" :key="Pindex + 'PProjectID' + packItem.SavingCardID">
                      <div v-for="(Project, projectIndex) in packItem.Project" :key="projectIndex + item.PackageCardAccountID + packItem.SavingCardID + Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                            {{ +treatInfo.Project.length + getCardTotalLength(treatInfo.SavingCard) + getCardTotalLength(treatInfo.TimeCard) + getCardTotalLength(treatInfo.GeneralCard) + treatInfo.Product.length + item.Project.length + getCurrentCardLength(item.SavingCard, Pindex, projectIndex) }}
                            {{ Project.ProjectName }}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ Project.Price | NumFormat }}</span>
                        </div>

                        <div class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >数量</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">{{ Project.Quantity }}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions && Project.CardPreferentialAmount != 0" class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >卡优惠</span
                          >
                          <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.CardPreferentialAmount | NumFormat}}</span> -->
                          <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount < 0">¥ {{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                          <span class="font_12 text_right line_height_24" v-if="Project.CardPreferentialAmount > 0">¥ -{{ Math.abs(Project.CardPreferentialAmount) | NumFormat }}</span>
                        </div>

                        <div v-if="cashierReceipt.TreatPromotions && Project.PricePreferentialAmount != 0" class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >手动改价</span
                          >
                          <!-- <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{Project.PricePreferentialAmount | NumFormat}}</span> -->
                          <span class="font_12 text_right line_height_24" v-if="Project.PricePreferentialAmount < 0">¥ {{ Math.abs(Project.PricePreferentialAmount) | NumFormat }}</span>
                          <span class="font_12 text_right line_height_24" v-if="Project.PricePreferentialAmount > 0">¥ -{{ Math.abs(Project.PricePreferentialAmount) | NumFormat }}</span>
                        </div>

                        <div class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >小计</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ Project.TotalAmount | NumFormat }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 套餐卡中 时效卡 -->
                    <div v-for="(packItem, Pindex) in item.TimeCard" :key="Pindex + 'PProjectID' + packItem.TimeCardID">
                      <div v-for="(Project, ProjectIndex) in packItem.Project" :key="ProjectIndex + item.PackageCardID + packItem.TimeCardID + Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                            {{
                              +treatInfo.Project.length +
                                getCardTotalLength(treatInfo.SavingCard) +
                                getCardTotalLength(treatInfo.TimeCard) +
                                getCardTotalLength(treatInfo.GeneralCard) +
                                treatInfo.Product.length +
                                item.Project.length +
                                getCardTotalLength(item.SavingCard) +
                                getCurrentCardLength(item.TimeCard, Pindex, projectIndex)
                            }}
                            {{ Project.ProjectName }}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ Project.Price | NumFormat }}</span>
                        </div>

                        <div class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >数量</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">{{ Project.Quantity }}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions && Project.CardPreferentialAmount != 0" class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >卡优惠</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">¥ {{ Project.CardPreferentialAmount | NumFormat }}</span>
                        </div>
                        <div class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >小计</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ Project.TotalAmount | NumFormat }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡中 通用次卡 -->
                    <div v-for="(packItem, Pindex) in item.GeneralCard" :key="Pindex + 'PProjectID' + packItem.GeneralCardID">
                      <div v-for="(Project, pIndex) in packItem.Project" :key="pIndex + item.PackageCardID + packItem.GeneralCardID + Project.ProjectID">
                        <div class="dis_flex">
                          <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                            {{
                              +treatInfo.Project.length +
                                getCardTotalLength(treatInfo.SavingCard) +
                                getCardTotalLength(treatInfo.TimeCard) +
                                getCardTotalLength(treatInfo.GeneralCard) +
                                treatInfo.Product.length +
                                item.Project.length +
                                getCardTotalLength(item.SavingCard) +
                                getCardTotalLength(item.TimeCard) +
                                getCurrentCardLength(item.GeneralCard, Pindex, projectIndex)
                            }}
                            {{ Project.ProjectName }}
                            <span class="font_12" size="mini" v-if="Project.IsLargess">【赠】</span>
                          </span>
                          <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ Project.Price | NumFormat }}</span>
                        </div>

                        <div class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >数量</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">{{ Project.Quantity }}</span>
                        </div>
                        <div v-if="cashierReceipt.TreatPromotions && Project.CardPreferentialAmount != 0" class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >卡优惠</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">¥ {{ Project.CardPreferentialAmount | NumFormat }}</span>
                        </div>
                        <div class="dis_flex">
                          <span
                            class="
                              font_12
                              color_gray
                              text-left
                              line_height_24
                              marlt_10
                            "
                            style="flex: 2"
                            >小计</span
                          >
                          <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ Project.TotalAmount | NumFormat }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem, Pindex) in item.Product" :key="Pindex + 'PProductID' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                          {{
                            +treatInfo.Project.length +
                              getCardTotalLength(treatInfo.SavingCard) +
                              getCardTotalLength(treatInfo.TimeCard) +
                              getCardTotalLength(treatInfo.GeneralCard) +
                              treatInfo.Product.length +
                              item.Project.length +
                              getCardTotalLength(item.SavingCard) +
                              getCardTotalLength(item.TimeCard) +
                              getCardTotalLength(item.GeneralCard) +
                              Pindex +
                              1
                          }}
                          {{ packItem.ProductName }}
                        </span>
                        <span v-if="cashierReceipt.TreatGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ packItem.Price | NumFormat }}</span>
                      </div>

                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >数量</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">{{ packItem.Quantity }}</span>
                      </div>
                      <div v-if="cashierReceipt.TreatPromotions && packItem.CardPreferentialAmount != 0" class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >卡优惠</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">¥ {{ packItem.CardPreferentialAmount | NumFormat }}</span>
                      </div>
                      <div class="dis_flex">
                        <span
                          class="
                            font_12
                            color_gray
                            text-left
                            line_height_24
                            marlt_10
                          "
                          style="flex: 2"
                          >小计</span
                        >
                        <span class="font_12 text_right line_height_24" style="flex: 1">￥ {{ packItem.TotalAmount | NumFormat }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{ treatInfo.Amount | NumFormat }}</span>
                </div>
                <div v-if="treatInfo.SaleBillPay && treatInfo.SaleBillPay.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="pay in treatInfo.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{ pay.Name }}</span>
                      <span class="flex_box text_right line_height_24">¥ {{ pay.Amount | NumFormat }}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="treatInfo.SaleBillPaySavingCardDeduction && treatInfo.SaleBillPaySavingCardDeduction.length != 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in treatInfo.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{ cardPay.Name }}</span>
                      <span class="flex_box text_right line_height_24">¥ {{ cardPay.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="treatInfo.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="treatInfo.PricePreferentialAmount > 0">-￥{{ treatInfo.PricePreferentialAmount | NumFormat }}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{ mathAbsData(treatInfo.PricePreferentialAmount) | NumFormat }}</span>
                </div>
                <div class="dis_flex" v-if="treatInfo.CardPreferentialAmount != 0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{ treatInfo.CardPreferentialAmount | NumFormat }}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div
                  class="
                    dis_flex
                    flex_dir_column
                    font_14 font_weight_600
                    flex_y_center
                    color_999
                    padbm_10
                  "
                >
                  <span>{{ cashierReceipt.WriteTextFirst }}</span>
                  <span>{{ cashierReceipt.WriteTextSecond }}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="treatCashierReceiptDialogVisible = false" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printTreatBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <!-- 消耗项目查看弹出框 -->
    <el-dialog title="消耗记录" :visible.sync="dialogVisibleThree" width="40%" :before-close="handleClose">
      <div>
        <el-table style="width: 100%" :data="dataListToo">
          <el-table-column prop="BillDate" label="消耗时间" width="180"> </el-table-column>
          <el-table-column prop="EntityName" label="门店名"> </el-table-column>
          <el-table-column prop="GoodsName" label="项目名"> </el-table-column>
          <el-table-column prop="HandlerName" label="经手人"> </el-table-column>
          <el-table-column prop="Remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="pagintionClass">
        <el-pagination v-if="dataListToo.length >= 1" background layout="prev, pager, next" @current-change="HandleCurrentChange" :current-page="pagination.page" :page-size="pagination.page_size" :total="pagination.Total"></el-pagination>
      </div>
    </el-dialog>

    <!-- 消耗储值卡查看弹出框 -->
    <el-dialog title="消耗记录" :visible.sync="dialogVisibleFour" width="40%" :before-close="handleClose">
      <div>
        <el-table style="width: 100%" :data="dataListThree">
          <el-table-column prop="BillDate" label="消耗时间" width="180"> </el-table-column>
          <el-table-column prop="EntityName" label="门店名"> </el-table-column>
          <el-table-column prop="GoodsName" label="项目名"> </el-table-column>
          <el-table-column prop="HandlerName" label="经手人"> </el-table-column>
          <el-table-column prop="Remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="pagintionClass">
        <el-pagination v-if="dataListThree.length >= 1" background layout="prev, pager, next" @current-change="HandleCurrentChangeToo" :current-page="pagination.page" :page-size="pagination.page_size" :total="pagination.Total"></el-pagination>
      </div>
    </el-dialog>

    <!-- 消耗时效卡查看弹出框 -->
    <el-dialog title="消耗记录" :visible.sync="dialogVisibleToo" width="40%" :before-close="handleClose">
      <div>
        <el-table style="width: 100%" :data="dataList">
          <el-table-column prop="BillDate" label="消耗时间" width="180"> </el-table-column>
          <el-table-column prop="EntityName" label="门店名"> </el-table-column>
          <el-table-column prop="GoodsName" label="项目名"> </el-table-column>
          <el-table-column prop="CurrentWeight" label="消耗时体重"> </el-table-column>
          <el-table-column prop="Quantity" label="消耗数量"> </el-table-column>
          <el-table-column prop="HandlerName" label="经手人"> </el-table-column>
          <el-table-column prop="Remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="pagintionClass">
        <el-pagination v-if="dataList.length >= 1" background layout="prev, pager, next" @current-change="HandleCurrentChangeThree" :current-page="pagination.page" :page-size="pagination.page_size" :total="pagination.Total"></el-pagination>
      </div>
    </el-dialog>

    <!-- 消耗通用持卡查看弹出框 -->
    <el-dialog title="消耗记录" :visible.sync="dialogVisibleFive" width="40%" :before-close="handleClose">
      <div>
        <el-table style="width: 100%" :data="dataListFour">
          <el-table-column prop="BillDate" label="消耗时间" width="180"> </el-table-column>
          <el-table-column prop="EntityName" label="门店名"> </el-table-column>
          <el-table-column prop="GoodsName" label="项目名"> </el-table-column>
          <el-table-column prop="HandlerName" label="经手人"> </el-table-column>
          <el-table-column prop="Quantity" label="消耗次数"> </el-table-column>
          <el-table-column prop="Remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="pagintionClass">
        <el-pagination v-if="dataListFour.length >= 1" background layout="prev, pager, next" @current-change="HandleCurrentChangeFour" :current-page="pagination.page" :page-size="pagination.page_size" :total="pagination.Total"></el-pagination>
      </div>
    </el-dialog>

    <!-- 消耗套餐卡查看弹出框 -->
    <el-dialog title="消耗记录" :visible.sync="dialogVisibleSix" width="40%" :before-close="handleClose">
      <div>
        <el-table style="width: 100%" :data="dataListFive">
          <el-table-column prop="BillDate" label="消耗时间" width="180"> </el-table-column>
          <el-table-column prop="EntityName" label="门店名"> </el-table-column>
          <el-table-column prop="GoodsName" label="项目名"> </el-table-column>
          <el-table-column prop="HandlerName" label="经手人"> </el-table-column>
          <el-table-column prop="Remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="pagintionClass">
        <el-pagination v-if="dataListFive.length > 0" background layout="prev, pager, next" @current-change="HandleCurrentChangeFive" :current-page="pagination.page" :page-size="pagination.page_size" :total="pagination.Total"></el-pagination>
      </div>
    </el-dialog>

    <!-- 消耗产品查看弹出框 -->
    <el-dialog title="消耗记录" :visible.sync="dialogVisibleSeven" width="40%" :before-close="handleClose">
      <div>
        <el-table style="width: 100%" :data="dataListSix">
          <el-table-column prop="BillDate" label="消耗时间" width="180"> </el-table-column>
          <el-table-column prop="EntityName" label="门店名"> </el-table-column>
          <el-table-column prop="GoodsName" label="项目名"> </el-table-column>
          <el-table-column prop="HandlerName" label="经手人"> </el-table-column>
          <el-table-column prop="Remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="pagintionClass">
        <el-pagination v-if="dataListSix.length > 0" background layout="prev, pager, next" @current-change="HandleCurrentChangeSix" :current-page="pagination.page" :page-size="pagination.page_size" :total="pagination.Total"></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/consumeGoods";
import date from "@/components/js/date";
import APITOO from "@/api/CRM/Customer/customerAccount";

import orderAPI from "@/api/iBeauty/Order/treatBill";
import printReceipt from "@/components/js/print";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";

var Enumerable = require("linq");
var socket;
export default {
  props: {
    billDate: String, //开单时间
    isSupplement: Boolean, // 是否补单
    CustomerID: Number, // 顾客编号
    customerFullName: String, // 顾客完整姓名
    customerPhoneNumber: String, // 顾客手机号
    TreatPermission: Object,
  },
  data() {
    /** 下表   项目 0 储值卡1  时效卡 2 通用次卡 3 套餐卡4  产品 5  */
    return {
      // 消耗记录
      pagination: {
        page: 1,
        Total: 0,
        page_size: 10,
      },
      dataList: [],
      dataListToo: [],
      dataListThree: [],
      dataListFour: [],
      dataListFive: [],
      dataListSix: [],
      dialogVisibleToo: false,
      dialogVisibleThree: false,
      dialogVisibleFour: false,
      dialogVisibleFive: false,
      dialogVisibleSix: false,
      dialogVisibleSeven: false,
      // -----------------------------

      customerID: "",
      treatCashierReceiptDialogVisible: false,
      loading: false,
      modalLoading: false,
      printLoading: false,
      dialogSavingCard: false,
      dialogGeneralCard: false,
      dialogTimeCard: false,
      dialogVisible: false,
      dialogBill: false,
      dialogDeduction: false,
      dialoConsumeSucceed: false,
      tabPane: "", //搜索商品的下表
      searchName: "", //搜索品项
      currentConsumeIndex: 0,
      remark: "",
      consumeBillNumber: "",
      consumeTotalAmount: 0,
      activeName: 0, // 默认展开选择项目的第一项
      tabHandle: "0",
      handlerName: "",
      /**  请求数据  */
      treatProjectAccountList: [],
      treatProductAccountList: [],
      treatSavingCardAccountList: [],
      treatSavingCardAccountProjectList: [],
      treatSavingCardAccountProjectList_i: [],
      treatGoodsPackageCardAccountList: [],
      treatGoodsPackageCardAccountDetailsList: [],
      treatGeneralCardAccountList: [],
      treatTimeCardAccountList: [],
      /**  经手人数据  */
      projectTreatHandlers: [],
      productTreatHandlers: [],
      savingCardTreatHandlers: [],
      /**  选择时的经手人 中间数组  */
      treatHandlerList: [],
      /** 将要消耗的品项 品项   */
      currentSelectProjectList: [],
      currentSelectProductList: [],
      currentSelectSavingCardList: [],
      currentSelectTimeCardList: [],
      currentSelectGeneralCardList: [],
      currentselectPackageCardList: [],
      /**  选中的 储值卡 时效卡 通用次卡  */
      /**  将要消耗的储值卡  */
      selectSavingcardItem: {},
      currentSavingcardProjectCategoty: [],
      currentSavingcardProject: [],
      currentSavingActiveName: [],
      searchSavingcardProjectName: "",
      savingCardConsumeItem: null, //
      /**  通用次卡  */
      selectGeneralcardItem: {},
      currentGeneralcarProjectCategoty: [],
      currentGeneralcarProject: [],
      currentGeneralcarActiveName: [],
      searchGeneralcarPorjectName: "",
      GeneralcarConsumeItem: null, //
      /**  时效卡  */
      selectTimecardItem: {},
      currentTimeCardProjectCategoty: [],
      currentTimeCardProject: [],
      currentTimeCardActiveName: [],
      searchTimeCardPorjectName: "",
      timeCardConsumeItem: null, //

      currentSelectCardItem: {},
      currentCardApplyProject: [], // 当前卡项适用项目
      currentCardApplyProjectChild: [],

      currentCategoryIndex: 0,

      currentPackCategoryIndex: 0,
      currentApplyCardName: "",
      cardSearchName: "",
      currentSelectPackageItem: {}, // 点击分类的套餐卡信息
      tempCurrentSelectPackageItem: {}, // 临时数据 当前选中的套餐卡
      isConsumePackage: false,
      packageDetailLoading: false,
      cashierReceipt: "",
      userInfo: "",
      treatInfo: "",
      itemList: {},
      treatParamsToo: {},
      xiaohaoItem: {},
    };
  },
  // 数据监听
  watch: {
    CustomerID(newValue) {
      this.customerID = newValue;
      if (this.customerID != null) {
        this.changMemberOrType();
      } else {
        this.clearConsumeNetWorkData();
      }
    },
  },
  /**  计算属性  */
  computed: {
    /**  计算将要消耗的业绩金额  */
    totalAmount: function() {
      var that = this;
      var total = 0;
      total += Enumerable.from(that.currentSelectProjectList).sum((i) => Number(i.TotalAmount));
      total += Enumerable.from(that.currentSelectProductList).sum((i) => Number(i.TotalAmount));

      /**  储值卡  */
      Enumerable.from(that.currentSelectSavingCardList).forEach((item) => {
        total += Enumerable.from(item.Projects).sum((i) => Number(i.TotalAmount));
      });

      /**  时效卡  */
      Enumerable.from(that.currentSelectTimeCardList).forEach((item) => {
        total += Enumerable.from(item.Projects).sum((i) => Number(i.TotalAmount));
      });

      /**  通用次卡  */
      Enumerable.from(that.currentSelectGeneralCardList).forEach((item) => {
        total += Enumerable.from(item.Projects).sum((i) => Number(i.TotalAmount));
      });

      Enumerable.from(that.currentselectPackageCardList).forEach((item) => {
        total += Enumerable.from(item.packageProjectList).sum((i) => Number(i.TotalAmount));
        total += Enumerable.from(item.packageProductList).sum((i) => Number(i.TotalAmount));

        Enumerable.from(item.packageSavingCardList).forEach((SavingCard) => {
          total += Enumerable.from(SavingCard.Projects).sum((i) => Number(i.TotalAmount));
        });

        Enumerable.from(item.packageTimeCardList).forEach((TimeCard) => {
          total += Enumerable.from(TimeCard.Projects).sum((i) => Number(i.TotalAmount));
        });
        Enumerable.from(item.packageGeneralList).forEach((GeneralCard) => {
          total += Enumerable.from(GeneralCard.Projects).sum((i) => i.TotalAmount);
        });
      });

      return total;
    },
  },
  /**  生命周期函数   实例被挂载后调用 生命周期内只执行一次  */
  mounted() {
    var that = this;
    that.customerID = that.CustomerID;
    socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });
        that.treatCashierReceiptDialogVisible = false;
        that.refundTreatCashierReceiptDialogVisible = false;
      }
    });
    that.userInfo = JSON.parse(localStorage.getItem("access-user"));
    /**  经手人  */
    that.treatProjectHandlerNet();
    that.treatProductHandlerNet();
    that.treatSavingCardHandlerNet();
    /**  已选顾客请求  */
    // if (that.customerID != null) {
    //   that.changMemberOrType();
    // }
  },
  /**  方法集合  */
  methods: {
    /* 修改当前体重 */
    changeCurrentWeight(item, val) {
      let that = this;
      let overweight = parseFloat(val) - parseFloat(item.MinWeight);
      if (parseFloat(val) > parseFloat(item.MinWeight)) {
        that.$message.error(`超重 ${overweight} 斤`);
      }
    },
    // 开单消耗时效卡查看点击事件
    chaKanClick(item, a) {
      this.xiaohaoItem = item;
      switch (a) {
        case 1:
          this.dialogVisibleThree = true;
          this.getChanKanToo(item);
          break;
        case 2:
          this.dialogVisibleFour = true;
          this.getChanKanThree(item);
          break;
        case 3:
          this.dialogVisibleToo = true;
          this.getChanKan(item);
          break;
        case 4:
          this.dialogVisibleFive = true;
          this.getChanKanFour(item);
          break;
        case 5:
          this.dialogVisibleSix = true;
          this.getChanKanFive(item);
          break;
        case 6:
          this.dialogVisibleSeven = true;
          this.getChanKanSix(item);
          break;
        default:
          console.log("这是swithc默认行为");
      }
    },

    // 时效卡消耗记录
    getChanKan(item) {
      console.log(item);
      var page = this.pagination.page;
      var params = {
        ID: item.ID,
        PageNum: page,
      };
      APITOO.timeCardTreatBill(params).then((res) => {
        console.log(res);
        if (res.StateCode == 200) {
          this.dataList = res.List;
          this.pagination.Total = res.Total;
          this.pagination.page_size = res.PageSize;
        }
      });
    },
    // 项目消耗记录
    getChanKanToo(item) {
      console.log(item);
      var page = this.pagination.page;
      var params = {
        ID: item.ID,
        PageNum: page,
      };
      APITOO.treatBill(params).then((res) => {
        console.log(res);
        if (res.StateCode == 200) {
          this.dataListToo = res.List;
          this.pagination.Total = res.Total;
          this.pagination.page_size = res.PageSize;
        }
      });
    },
    // 储值卡消耗记录
    getChanKanThree(item) {
      console.log(item);
      var page = this.pagination.page;
      var params = {
        ID: item.AccountID,
        PageNum: page,
      };
      APITOO.savingCardTreatBillToo(params).then((res) => {
        console.log(res);
        if (res.StateCode == 200) {
          this.dataListThree = res.List;
          this.pagination.Total = res.Total;
          this.pagination.page_size = res.PageSize;
        }
      });
    },
    // 通用次卡消耗记录
    getChanKanFour(item) {
      var page = this.pagination.page;
      var params = {
        ID: item.ID,
        PageNum: page,
      };
      APITOO.generalCardTreatBill(params).then((res) => {
        console.log(res);
        if (res.StateCode == 200) {
          this.dataListFour = res.List;
          this.pagination.Total = res.Total;
          this.pagination.page_size = res.PageSize;
        }
      });
    },
    // 套餐卡消耗记录
    getChanKanFive(item) {
      var page = this.pagination.page;
      var params = {
        ID: item.ID,
        PageNum: page,
      };
      APITOO.generalCardTreatBill(params).then((res) => {
        console.log(res);
        if (res.StateCode == 200) {
          this.dataListFive = res.List;
          this.pagination.Total = res.Total;
          this.pagination.page_size = res.PageSize;
        }
      });
    },
    // 产品消耗记录
    getChanKanSix(item) {
      var page = this.pagination.page;
      var params = {
        ID: item.ID,
        PageNum: page,
      };
      APITOO.productAccountTreatBill(params).then((res) => {
        console.log(res);
        if (res.StateCode == 200) {
          this.dataListSix = res.List;
          this.pagination.Total = res.Total;
          this.pagination.page_size = res.PageSize;
        }
      });
    },

    // 弹框关闭事件
    handleClose() {
      console.log("我关闭啦");
      this.dialogVisibleToo = false;
      this.dialogVisibleThree = false;
      this.dialogVisibleFour = false;
      this.dialogVisibleFive = false;
      this.dialogVisibleSix = false;
      this.dialogVisibleSeven = false;
      this.pagination.page = 1;
    },
    // 分页
    HandleCurrentChange(page) {
      console.log(page);
      this.pagination.page = page;
      this.getChanKanToo(this.xiaohaoItem);
    },
    HandleCurrentChangeToo(page) {
      this.pagination.page = page;
      this.getChanKanThree(this.xiaohaoItem);
    },
    HandleCurrentChangeThree(page) {
      this.pagination.page = page;
      this.getChanKan(this.xiaohaoItem);
    },
    HandleCurrentChangeFour(page) {
      this.pagination.page = page;
      this.getChanKanFour(this.xiaohaoItem);
    },
    HandleCurrentChangeFive(page) {
      this.pagination.page = page;
      this.getChanKanFive(this.xiaohaoItem);
    },
    HandleCurrentChangeSix(page) {
      this.pagination.page = page;
      this.getChanKanSix(this.xiaohaoItem);
    },

    /*==========================================================================*/

    //清空按钮触发s
    clearAndSearch() {
      this.searchSavingCardProjectClick();
    },
    //时效卡
    clearAndTimeSearch() {
      this.searchTimeCardProjectClick();
    },
    clearAndGenterSearch() {
      this.searchGeneralCardProjectClick();
    },
    /**  调用 请求网络 数据 */
    changMemberOrType: function() {
      var that = this;
      if (that.customerID != null && that.getBillDate() != null) {
        that.tabPane = "";
        /**  存量信息  */
        that.searchGoodsClick();
      } else {
        that.clearConsumeNetWorkData();
      }
    },
    /**  会员清除时 清除上一个顾客的存量  */
    clearConsumeNetWorkData() {
      var that = this;
      that.tabPane = "";
      that.treatProjectAccountList = [];
      that.treatProductAccountList = [];
      (that.treatSavingCardAccountList = []), (that.treatSavingCardAccountProjectList = []);
      that.treatSavingCardAccountProjectList_i = [];
      that.treatGoodsPackageCardAccountList = [];
      that.treatGoodsPackageCardAccountDetailsList = {};
      that.treatGeneralCardAccountList = [];
      that.treatTimeCardAccountList = [];

      that.currentSelectProjectList = [];
      that.currentSelectProductList = [];
      that.currentSelectSavingCardList = [];
      that.currentSelectTimeCardList = [];
      that.currentSelectGeneralCardList = [];
      that.currentselectPackageCardList = [];
    },
    /** 修改时间清除 已选项   */
    clearConsumeSelectItem() {
      let that = this;
      that.currentSelectProjectList = [];
      that.currentSelectProductList = [];
      that.currentSelectSavingCardList = [];
      that.currentSelectTimeCardList = [];
      that.currentSelectGeneralCardList = [];
      that.currentselectPackageCardList = [];
    },
    /**  点击switch切换  将搜索内容 置空 */
    tabClick: function() {
      var that = this;
      that.searchName = "";
    },

    /**======================================================================  */
    /**  项目消耗  */
    consumeProjectClick(item) {
      var that = this;
      if (item.ValidBalance <= that.getCurrentProjectQuantity(item.ID)) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }

      var tempHandle = [];
      Enumerable.from(that.projectTreatHandlers).forEach((item) => {
        tempHandle.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      that.currentSelectProjectList.push({
        ID: item.ID,
        Name: item.Name,
        Alias: item.Alias,
        ValidBalance: item.ValidBalance,
        ProjectID: item.ProjectID,
        Quantity: 1,
        Price: item.Price,
        UnitAmount: item.Amount,
        TotalAmount: item.Amount,
        IsLargess: item.IsLargess,
        ProjectTreatHandler: [],
        HandleTypeList: tempHandle,
      });
    },

    projectItemQuantityChangeClick(item) {
      item.TotalAmount = item.UnitAmount * item.Quantity;
    },

    getCurrentProjectQuantity: function(itemID) {
      return Enumerable.from(this.currentSelectProjectList)
        .where((i) => i.ID == itemID)
        .sum("$.Quantity");
    },

    removeProjectSelectItemClick(index) {
      this.currentSelectProjectList.splice(index, 1);
    },

    /**======================================================================  */
    /**  产品  */
    consumeProductClick(item) {
      var that = this;
      if (item.ValidBalance <= that.getCurrentProductQuantity(item.ID)) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }
      var tempHandle = [];
      that.productTreatHandlers.forEach((item) => {
        tempHandle.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      that.currentSelectProductList.push({
        Name: item.Name,
        Alias: item.Alias,
        ValidBalance: item.ValidBalance,
        ID: item.ID,
        ProductID: item.ProductID,
        Quantity: 1,
        Price: item.Price,
        UnitAmount: item.Amount,
        TotalAmount: item.Amount,
        IsLargess: item.IsLargess,
        ProductTreatHandler: [],
        HandleTypeList: tempHandle,
      });
    },

    productItemQuantityChangeClick(item) {
      item.TotalAmount = item.UnitAmount * item.Quantity;
    },

    getCurrentProductQuantity: function(itemID) {
      return Enumerable.from(this.currentSelectProductList)
        .where((i) => i.ID == itemID)
        .sum("$.Quantity");
    },

    removeSelectProductItemClick(index) {
      this.currentSelectProductList.splice(index, 1);
    },
    /**======================================================================  */
    /**  储值卡弹窗  */
    /**  搜索 储值卡使用项目  */
    searchSavingCardProjectClick: function() {
      var that = this;
      that.currentSavingcardProjectCategoty = [];
      that.currentSavingcardProject = [];
      that.currentSavingActiveName = [];
      that.treatSavingCardAccountProjectNet(false);
    },
    /** 储值卡使用项目 点击分类   */
    savingCardProjectCategoryClick(item, index) {
      var that = this;
      that.currentCategoryIndex = index;
      that.currentSavingcardProject = item.Child;
      that.currentSavingActiveName = Enumerable.from(that.currentSavingcardProject)
        .select((val) => val.ID)
        .toArray();
    },
    /** 添加储值卡消耗项目   */
    addSavingCardApplyProjectItem(item) {
      var that = this;
      if (that.isConsumePackage) {
        that.addPackageItemSavingCardProject(item);
      } else {
        that.addSavingCardProject(item);
      }
    },

    /**======================================================================  */
    /**  储值卡  */
    /**  点击储值卡消耗  */
    consumeSavingCardClick(item) {
      var that = this;
      that.isConsumePackage = false;
      if (item.ValidBalance == 0) {
        that.$message.error({
          message: "该储值卡有效余额不足，请选择其他储值卡",
          duration: 2000,
        });
        return;
      }
      that.selectSavingcardItem = item;
      that.currentSavingcardProjectCategoty = [];
      that.currentSavingcardProject = [];
      that.searchSavingcardProjectName = "";
      that.savingCardConsumeItem = null;
      that.savingCardConsumeItem = Enumerable.from(that.currentSelectSavingCardList).firstOrDefault((i) => i.ID == item.ID);
      that.treatSavingCardAccountProjectNet(true);
    },

    /** 储值卡添加项目   */
    addSavingCardProject(item) {
      var that = this;
      var consumeAmount = that.getCurrentSavingCardConsumeAmount(that.savingCardConsumeItem, that.isConsumePackage); //将要消耗的金额
      var ValidBalance = that.selectSavingcardItem.ValidBalance; // 当前储值卡的有效余额
      if (Number(ValidBalance) == Number(consumeAmount)) {
        that.$message.error({
          message: "储值卡余额不足。",
          duration: 2000,
        });
        return;
      }
      var tempConsumeAmount = Number(consumeAmount) + Number(item.PreferentialPrice);
      /**  判断 将要消耗的金额是否大于储值卡的有效余额  */
      if (tempConsumeAmount > ValidBalance) {
        if (that.TreatPermission.ModifyPrices_TreatSavingCard) {
          var tempPrice = ValidBalance - consumeAmount;
          var tempStr = "卡剩余" + tempPrice.toFixed(2) + " 元，改项目需要消耗" + Number(item.PreferentialPrice).toFixed(2) + "元，如果需要继续操作，此项目的耗卡金额将修改为 " + tempPrice.toFixed(2) + " 元";
          that
            .$confirm(tempStr, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              that.judgeSavingCardConsumeProject(item, Number(tempPrice).toFixed(2));
              that.dialogSavingCard = false;
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          that.$message.error({
            message: "卡剩余金额不足，请更换其他卡",
            duration: 2000,
          });
        }
      } else {
        that.judgeSavingCardConsumeProject(item, item.PreferentialPrice);
        that.dialogSavingCard = false;
      }
    },
    /**  添加消耗项目   */
    judgeSavingCardConsumeProject(item, itemAmount) {
      var that = this;
      // /**  消耗的项目  */
      var PerformanceRatio = Number((itemAmount / item.PreferentialPrice) * 100).toFixed(2);
      /**  消耗项目  */
      var project = {
        Name: item.Name,
        Alias: item.Alias,
        ID: item.ID,
        Price: item.Price,
        Quantity: 1,
        UnitAmount: item.PreferentialPrice,
        TotalAmount: itemAmount,
        PreferentialPrice: item.PreferentialPrice,
        PopoveVisible: false,
        PopoveAmount: itemAmount,
        PerformanceRatio: PerformanceRatio,
        PricePreferentialAmount: Number(Number(item.PreferentialPrice) - Number(itemAmount)).toFixed(2), //手动改价优惠
        CardPreferentialAmount: Number(item.CardPreferentialAmount).toFixed(2), //卡优惠价格
        CardPreferentialAmountTotal: Number(item.CardPreferentialAmount).toFixed(2), //卡优惠价格
        SavingCardTreatHandler: [],
        HandleTypeList: [],
      };
      /**  经手人  */
      that.savingCardTreatHandlers.forEach((item) => {
        project.HandleTypeList.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });
      /**  将要消耗的储值卡  */
      if (JSON.stringify(that.savingCardConsumeItem) == "{}" || that.savingCardConsumeItem == null || typeof that.savingCardConsumeItem == "undefined") {
        that.savingCardConsumeItem = {
          ID: that.selectSavingcardItem.ID,
          AccountID: that.selectSavingcardItem.AccountID,
          SavingCardID: that.selectSavingcardItem.SavingCardID,
          Name: that.selectSavingcardItem.Name,
          Alias: that.selectSavingcardItem.Alias,
          Type: that.selectSavingcardItem.Type,
          ValidBalance: that.selectSavingcardItem.ValidBalance,
          SavingCardItem: that.selectSavingcardItem,
          Projects: [project],
        };
        that.currentSelectSavingCardList.push(that.savingCardConsumeItem);
      } else {
        that.savingCardConsumeItem.Projects.push(project);
      }
    },
    /**  计算 储值卡将要消耗金额  */
    getCurrentSavingCardConsumeAmount: function(savingCard) {
      if (!savingCard) return 0;

      return Enumerable.from(savingCard.Projects).sum((i) => Number(i.TotalAmount));

      // if (
      //   JSON.stringify(savingCard) == "{}" ||
      //   savingCard == null ||
      //   this.isConsumePackage == null
      // ) {
      //   return 0;
      // }
      // var consumeAmount = 0;

      //   savingCard.Projects.forEach((item) => {
      //     consumeAmount += Number(item.Amount);
      //   });

      // return Number(consumeAmount);
    },
    /**  修改储值卡消耗项目的数量  */
    changeSavingCardConsumeProject(currentValue, oldValue, item, project) {
      var that = this;
      var consumeAmount = that.getCurrentSavingCardConsumeAmount(item); //  当前 消耗金额
      if (Number(item.ValidBalance) < Number(consumeAmount) - Number(project.TotalAmount) + Number(project.PreferentialPrice * project.Quantity)) {
        that.$message.error({
          message: "当前储值卡余额不足",
          duration: 2000,
        });
        that.$nextTick(() => {
          project.Quantity = oldValue;
        });
        return;
      }

      var curAmount = project.Quantity * project.PreferentialPrice + consumeAmount - project.PreferentialPrice * oldValue;

      if (curAmount > item.ValidBalance) {
        var MaxQuantity = Math.floor((item.ValidBalance - consumeAmount + project.TotalAmount) / project.PreferentialPrice);
        that.$nextTick(() => {
          project.Quantity = MaxQuantity;
          project.TotalAmount = Number(parseFloat(project.PreferentialPrice * project.Quantity).toFixed(2));
          project.CardPreferentialAmountTotal = parseFloat(project.CardPreferentialAmount * project.Quantity).toFixed(2);
          project.PopoveAmount = project.TotalAmount;
          project.PerformanceRatio = 100;
          project.PricePreferentialAmount = 0;
        });
        that.$message.error({
          message: "该储值卡有效余额不足。",
          duration: 2000,
        });
        return;
      } else {
        project.TotalAmount = Number(parseFloat(Number(project.PreferentialPrice) * Number(project.Quantity)).toFixed(2));
        project.CardPreferentialAmountTotal = parseFloat(project.CardPreferentialAmount * project.Quantity).toFixed(2);
        project.PopoveAmount = project.TotalAmount;
        project.PerformanceRatio = 100;
        project.PricePreferentialAmount = 0;
      }
    },
    /**  储值卡删除事件  */
    removeSavingCardSelectItemClick(index, pIndex) {
      var that = this;
      that.currentSelectSavingCardList[index].Projects.splice(pIndex, 1);
      if (that.currentSelectSavingCardList[index].Projects.length <= 0) {
        that.currentSelectSavingCardList.splice(index, 1);
      }
    },
    /**  储值卡手动改价 输入框事件  */
    savingCardPopInputChange(item, type) {
      switch (type) {
        case 0:
          var temp = (item.PopoveAmount / (item.PreferentialPrice * item.Quantity)) * 100;
          item.PerformanceRatio = Number(temp.toFixed(2));
          break;
        case 1:
          var temp_1 = (item.PreferentialPrice * item.Quantity * item.PerformanceRatio) / 100;
          item.PopoveAmount = Number(temp_1).toFixed(2);
          break;
        default:
          break;
      }
    },
    /**    */
    showPopover(item) {
      item.PopoveAmount = Number(item.TotalAmount).toFixed(2);
      var temp = (item.TotalAmount / (item.PreferentialPrice * item.Quantity)) * 100;
      item.PerformanceRatio = Number(temp).toFixed(2);
    },
    /**    */
    savingCardPopoverClickConfirm(item, savingItem) {
      var that = this;
      var currentAmount = Number(that.getCurrentSavingCardConsumeAmount(savingItem)) - Number(item.TotalAmount) + Number(item.PopoveAmount);
      if (currentAmount > savingItem.ValidBalance) {
        that.$message.error({
          message: "储值卡有效余额不足,请修改消耗金额",
          duration: 2000,
        });
        return;
      }
      var temp = Number(item.PreferentialPrice * item.Quantity) - Number(item.PopoveAmount);
      item.PricePreferentialAmount = Number(temp).toFixed(2);
      item.PopoveVisible = false;
      item.TotalAmount = Number(item.PopoveAmount).toFixed(2);
    },

    /**======================================================================  */
    /**  搜索 时效卡使用项目  */
    searchTimeCardProjectClick: function() {
      var that = this;
      that.currentTimeCardProjectCategoty = [];
      that.currentTimeCardProject = [];
      that.currentTimeCardActiveName = [];
      that.treatTimeCardAccountProjectNet(false);
    },
    /**  时效卡 适用项目分类点击  */
    timeCardProjectCategoryClick(item, index) {
      var that = this;
      console.log(item);
      that.currentCategoryIndex = index;
      that.currentTimeCardProject = item.Child;
      that.currentTimeCardActiveName = Enumerable.from(that.currentTimeCardProject)
        .select((val) => val.ID)
        .toArray();
    },
    /** 添加时效卡 适用项目   */
    addTimeCardApplyProjectItem(item) {
      console.log(item);
      var that = this;
      if (that.isConsumePackage) {
        that.addPackageTimeCardProjectClick(item);
      } else {
        that.addTimeCardProjectCliak(item);
      }
    },

    /**======================================================================  */
    /**  时效卡消耗  */
    /** 时效卡存量点击   */
    consumeTimeCardClick(item) {
      console.log(item);
      var that = this;
      if (item.IsRefund) return;
      that.isConsumePackage = false;
      // if (item.ValidBalance == 0 && item.ArrearAmount > 0) {
      //   that.$message.error({
      //     message: "此时效卡有欠款，有效次数不足",
      //     duration: 2000,
      //   });
      //   return;
      // }
      // if(!item.IsEffective){
      //   that.$message.error({
      //     message: "此时效卡有效次数不足",
      //     duration: 2000,
      //   });
      //   return;
      // }

      if (item.ConsumeCycle == 0 || item.CycleLimitAmount == 0) {
        /**  如果 消耗周期 或者 周期次数 为零 则消耗不做限制  */
        that.selectTimecardItem = item;
        that.currentTimeCardProjectCategoty = [];
        that.currentTimeCardProject = [];
        that.searchTimeCardPorjectName = "";
        that.treatTimeCardAccountProjectNet(true, item);
      } else {
        var ValidBalance = item.CycleLimitAmount - item.ConsumeCycleAmount; //周期次数 减去周期内已耗用次数 等于有效次数
        if (ValidBalance <= 0) {
          that.$message.error({
            message: "周期内消耗次数不足",
            duration: 2000,
          });
          return;
        }
        that.selectTimecardItem = item;
        that.currentTimeCardProjectCategoty = [];
        that.currentTimeCardProject = [];
        that.searchTimeCardPorjectName = "";
        that.treatTimeCardAccountProjectNet(true, item);
      }
    },

    /** 时效卡   */
    addTimeCardProjectCliak(item) {
      var that = this;
      /**  周期内有效的耗用次数  */
      var CycleValidBalance = that.selectTimecardItem.CycleLimitAmount - that.selectTimecardItem.ConsumeCycleAmount;
      /**  将要耗用的次数  */
      var curAmount = that.getTimeCardQuantity(that.selectTimecardItem.ID);

      /**  如果 消耗周期 与周期次数任意为 0 切在不欠款的情况下不限制消耗次数  */
      if (that.selectTimecardItem.CycleLimitAmount != 0 && that.selectTimecardItem.ConsumeCycle != 0) {
        if (curAmount >= CycleValidBalance) {
          /**  判断周期次数  */
          that.$message.error({
            message: "周期内有效次数不足",
            duration: 2000,
          });
          return;
        }
      }

      /**   如果时效卡有欠款 则根据有效次数限制消耗次数 */
      // if (that.selectTimecardItem.ArrearAmount > 0) {
      //   //有欠款
      //   if (curAmount >= that.selectTimecardItem.ValidBalance) {
      //     /**  当前消耗次数是否大于 有效次数  */

      //     that.$message.error({
      //       message:
      //         "当前时效卡存在欠款，累计消耗次数不能超过" +
      //         that.selectTimecardItem.ValidBalance +
      //         "次",
      //       duration: 2000,
      //     });
      //     return;
      //   }
      // }
      /**  当前业绩金额  */
      var currentTimeAmount = that.selectTimecardItem.Amount;
      /**  如果 消耗次数大于 等于 剩余业绩次数 则消耗金额为零  */
      if (curAmount >= that.selectTimecardItem.PerformanceBalance) {
        currentTimeAmount = 0;
      }

      var tempHandle = [];
      Enumerable.from(that.projectTreatHandlers).forEach((item) => {
        tempHandle.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });
      var Project = {
        TargetWeight: item.TargetWeight,
        CustomerTargetWeight: item.CustomerTargetWeight,
        MinWeight: item.MinWeight,
        SaleCurrentWeight: item.SaleCurrentWeight,
        Name: item.Name,
        Alias: item.Alias,
        ID: item.ID,
        Quantity: 1, //数量
        IsLargess: that.selectTimecardItem.IsLargess,
        Price: item.Price, // 项目单价
        UnitAmount: that.selectTimecardItem.Amount, //  消耗参考单价
        TotalAmount: currentTimeAmount, // 消耗 业绩金额
        TimeCardTreatHandler: [],
        HandleTypeList: tempHandle,
      };

      /**  取出时效卡  */
      var timeCardItem = Enumerable.from(that.currentSelectTimeCardList).firstOrDefault((i) => i.ID == that.selectTimecardItem.ID);
      /**  判断时效卡是否存在  */
      if (typeof timeCardItem == "undefined") {
        that.currentSelectTimeCardList.push({
          ID: that.selectTimecardItem.ID,
          TimeCardID: that.selectTimecardItem.TimeCardID,
          Name: that.selectTimecardItem.Name,
          Alias: that.selectTimecardItem.Alias,
          IsLargess: that.selectTimecardItem.IsLargess,
          ArrearAmount: that.selectTimecardItem.ArrearAmount,
          Amount: that.selectTimecardItem.Amount,
          UnitAmount: that.selectTimecardItem.Amount,
          CycleLimitAmount: that.selectTimecardItem.CycleLimitAmount, // 周期内次数
          ConsumeCycle: that.selectTimecardItem.ConsumeCycle,
          ConsumeCycleAmount: that.selectTimecardItem.ConsumeCycleAmount, // 周期内已消耗次数
          ValidBalance: that.selectTimecardItem.ValidBalance, //周期内有效次数
          PerformanceTimes: that.selectTimecardItem.PerformanceTimes, // 总业绩次数
          PerformanceBalance: that.selectTimecardItem.PerformanceBalance, // 剩余业绩次数
          TimeCardItem: that.selectTimecardItem,
          Projects: [Project],
        });
      } else {
        if (timeCardItem.Projects.length > 0) {
          that.$message.warning("每张时效卡只可以选择一项服务");
          return;
        }
        timeCardItem.Projects.push(Project);
      }
      that.dialogTimeCard = false;
    },
    /** 获取 时效卡的最大值   */
    getTimeCardMaxQuantity(timerCard) {
      if (timerCard.ArrearAmount > 0) {
        //有欠款的情况
        if (timerCard.CycleLimitAmount != 0 && timerCard.ConsumeCycle != 0) {
          // 周期次数 有限制
          if (timerCard.ValidBalance > Number(timerCard.CycleLimitAmount) - Number(timerCard.ConsumeCycleAmount)) {
            // 判断有效周期次数 是否小于 欠款时的有效次数
            return Number(timerCard.CycleLimitAmount) - Number(timerCard.ConsumeCycleAmount);
          }
        }
        // return timerCard.ValidBalance;
        return Number.MAX_VALUE;
      } else {
        if (timerCard.CycleLimitAmount != 0 && timerCard.ConsumeCycle != 0) {
          /**  周期次数 减去 周期内已消耗次数  */
          return Number(timerCard.CycleLimitAmount) - Number(timerCard.ConsumeCycleAmount);
        } else {
          /**  如果没有欠款 且 周期 和周期次数均不为 0 则返回最大值  */
          return Number.MAX_VALUE;
        }
      }
    },
    /**  计算时效卡周期内已选的消耗次数  */
    getTimeCardQuantity: function(timerCardID) {
      if (!timerCardID) return 0;
      var timerCard = Enumerable.from(this.currentSelectTimeCardList).firstOrDefault((i) => i.ID == timerCardID);
      if (typeof timerCard == "undefined") return 0;
      return Enumerable.from(timerCard.Projects).sum((i) => Number(i.Quantity));
    },
    /**  获取有效业绩次数 排除当前项目 */
    getTimeCardConsumePerformanceAmount(timeCard, Project) {
      if (!timeCard) return 0;
      var ValidBalance =
        Enumerable.from(
          Enumerable.from(timeCard.Projects)
            .where((i) => i != Project)
            .toArray()
        ).sum((i) => Number(i.TotalAmount)) / timeCard.UnitAmount;
      return timeCard.ValidBalance - ValidBalance;
    },
    /**  时效卡修改数量   */
    timeItemQuantityChangeClick(currentValue, oldValue, timeCard, Project) {
      var that = this;
      var curVailAmount = that.getTimeCardConsumePerformanceAmount(timeCard, Project);
      if (curVailAmount > 0) {
        if (Project.Quantity >= curVailAmount) {
          Project.TotalAmount = parseFloat(Project.UnitAmount * curVailAmount).toFixed(2);
        } else {
          Project.TotalAmount = parseFloat(Project.UnitAmount * Project.Quantity).toFixed(2);
          that.timeCardAllocationValidQuantity(timeCard, Project, curVailAmount);
        }
      }
    },

    /**  分配有效余额  */
    timeCardAllocationValidQuantity(timeCard, Project, curVailAmount) {
      var surplusAmount = curVailAmount - Project.TotalAmount / Project.UnitAmount;
      if (timeCard.Projects.length > 1 && surplusAmount > 0) {
        for (let index = 0; index < timeCard.Projects.length; index++) {
          var ocation = timeCard.Projects[index];
          if (ocation != Project) {
            var iPerAmount = ocation.TotalAmount / ocation.UnitAmount; //业绩次数
            if (ocation.Quantity > iPerAmount) {
              var diff = ocation.Quantity - iPerAmount > surplusAmount ? surplusAmount : ocation.Quantity - iPerAmount;
              ocation.TotalAmount = parseFloat(parseFloat(diff * ocation.UnitAmount) + parseFloat(ocation.TotalAmount)).toFixed(2);
              surplusAmount -= diff;
              if (surplusAmount <= 0) {
                return;
              }
            }
          }
        }
      }
    },

    /** 删除 重新分配   */
    timeCardRemoveAllOcationValidQuantity(timeCard) {
      var timeCardTotalAmount = Enumerable.from(timeCard.Projects).sum((i) => Number(i.TotalAmount)) / timeCard.UnitAmount; // 消耗的总金额
      // 剩余的业绩次数
      var diffAmount = timeCard.ValidBalance - timeCardTotalAmount;
      if (diffAmount > 0) {
        for (let index = 0; index < timeCard.Projects.length; index++) {
          var Project = timeCard.Projects[index];
          // 当前项目业绩次数
          var ProjectPerQuan = Project.TotalAmount / Project.UnitAmount;
          // 判断 是否 有非业绩次数
          if (Project.Quantity > ProjectPerQuan) {
            let tempDiff = Project.Quantity - ProjectPerQuan;
            var different = tempDiff > diffAmount ? diffAmount : tempDiff;
            Project.TotalAmount = parseFloat(parseFloat(different * Project.UnitAmount) + parseFloat(Project.TotalAmount)).toFixed(2);
            diffAmount -= different;
            if (diffAmount <= 0) {
              return;
            }
          }
        }
      }
    },

    /**   删除 */
    timeRemoveSelectItemClick(index, ProjectIndex) {
      var that = this;
      that.currentSelectTimeCardList[index].Projects.splice(ProjectIndex, 1);

      if (that.currentSelectTimeCardList[index].Projects.length <= 0) {
        that.currentSelectTimeCardList.splice(index, 1);
      } else {
        var timeCard = that.currentSelectTimeCardList[index];
        that.timeCardRemoveAllOcationValidQuantity(timeCard);
      }
    },

    /**======================================================================  */
    /**  搜索 通用次卡使用项目  */
    searchGeneralCardProjectClick: function() {
      var that = this;
      that.currentGeneralcarProjectCategoty = [];
      that.currentGeneralcarProject = [];
      that.currentGeneralcarActiveName = [];
      that.treatGeneralCardAccountProjectNet(false);
    },
    /**  时效卡 适用项目分类点击  */
    generalCardProjectCategoryClick(item, index) {
      var that = this;
      that.currentCategoryIndex = index;
      that.currentGeneralcarProject = item.Child;
      // that.currentGeneralcarActiveName = that.currentGeneralcarProject[0].ID;
      that.currentGeneralcarActiveName = Enumerable.from(that.currentGeneralcarProject)
        .select((val) => val.ID)
        .toArray();
    },
    /**  添加通用次卡使用项目  */
    addGeneralclickCardApplyProjectItem(project) {
      var that = this;
      if (that.isConsumePackage) {
        that.addPackageGeneralCardProjectClick(project);
      } else {
        that.addGeneralProjectClick(project);
      }
    },

    /**======================================================================  */
    /**  通用次卡  */
    /**    */
    consumeGeneralCardClick(item) {
      var that = this;
      that.isConsumePackage = false;
      that.isConsumePackage = null;
      that.selectGeneralcardItem = item;
      that.currentGeneralcarProjectCategoty = [];
      that.currentGeneralcarProject = [];
      that.currentGeneralcarActiveName = [];
      that.searchGeneralcarPorjectName = "";
      that.treatGeneralCardAccountProjectNet(true);
    },

    /**  通用次卡添加 项目  */
    addGeneralProjectClick(item) {
      var that = this;
      if (that.selectGeneralcardItem.ValidBalance < that.getGeneralCardQuantity(that.selectGeneralcardItem.ID) + item.ConsumeAmount) {
        that.$message.error({ message: "通用次卡次数不足", duration: 2000 });
        return;
      }
      if (item.LimitConsumeTimes != 0 && item.LimitConsumeTimes - item.Quantity < that.getGeneralCardProQua(that.selectGeneralcardItem.ID, item.ID) + item.ConsumeAmount) {
        that.$message.error({
          message: "该项目以达到最大可消耗次数",
          duration: 2000,
        });
        return;
      }
      /**  通用次卡使用项目信息  */
      var Project = {
        Name: item.Name,
        Alias: item.Alias,
        Price: item.Price,
        ID: item.ID,
        Quantity: 1,
        LimitConsumeTimes: item.LimitConsumeTimes,
        quantity: item.Quantity,
        UnitAmount: that.selectGeneralcardItem.Amount,
        TotalAmount: that.selectGeneralcardItem.Amount * item.ConsumeAmount,
        ConsumeAmount: item.ConsumeAmount,
        ProjectTreatHandler: [],
        HandleTypeList: [],
      };

      Enumerable.from(that.projectTreatHandlers).forEach((item) => {
        Project.HandleTypeList.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      var generalCard = Enumerable.from(that.currentSelectGeneralCardList).firstOrDefault((i) => i.ID == that.selectGeneralcardItem.ID);

      if (typeof generalCard == "undefined") {
        that.currentSelectGeneralCardList.push({
          ID: that.selectGeneralcardItem.ID,
          GeneralCardID: that.selectGeneralcardItem.GeneralCardID,
          Name: that.selectGeneralcardItem.Name,
          Alias: that.selectGeneralcardItem.Alias,
          ValidBalance: that.selectGeneralcardItem.ValidBalance,
          Price: that.selectGeneralcardItem.Price,
          Amount: that.selectGeneralcardItem.Amount,
          IsLargess: that.selectGeneralcardItem.IsLargess,
          ArrearAmount: that.selectGeneralcardItem.ArrearAmount,
          GeneralCardItem: that.selectGeneralcardItem,
          Projects: [Project],
        });
      } else {
        generalCard.Projects.push(Project);
      }
      that.dialogGeneralCard = false;
    },

    /** 通用次卡修改数量   */
    generalItemQuantityChangeClick(currentValue, oldValue, item, Project) {
      Project.TotalAmount = Number(parseFloat(Project.UnitAmount * Project.Quantity * Project.ConsumeAmount).toFixed(2));
    },
    /**  通用次卡将要消耗次数  */
    getGeneralCardQuantity: function(generalID) {
      var that = this;
      if (!generalID) return 0;
      var generalItem = Enumerable.from(that.currentSelectGeneralCardList).firstOrDefault((i) => i.ID == generalID);
      if (typeof generalItem == "undefined") return 0;
      return Enumerable.from(generalItem.Projects).sum((i) => Number(Number(i.Quantity) * Number(i.ConsumeAmount)));
    },
    // 当前项目消耗次数
    getGeneralCardProQua(generalID, ProID) {
      var that = this;
      if (!generalID) return 0;
      var generalItem = Enumerable.from(that.currentSelectGeneralCardList).firstOrDefault((i) => i.ID == generalID);
      if (typeof generalItem == "undefined") return 0;
      return Enumerable.from(generalItem.Projects).sum((i) => {
        if (i.ID == ProID) {
          return Number(Number(i.Quantity) * Number(i.ConsumeAmount));
        } else {
          return 0;
        }
      });
    },
    /**  获取 通用次卡的最大值  */
    getGeneralCardProjectMaxQuantity(generalCard, Project) {
      var that = this;
      const num1 = Math.floor((generalCard.ValidBalance - that.getGeneralCardQuantity(generalCard.ID)) / Project.ConsumeAmount) + Project.Quantity;
      const num2 = Math.floor((Project.LimitConsumeTimes - Project.quantity - that.getGeneralCardProQua(generalCard.ID, Project.ID)) / Project.ConsumeAmount) + Project.Quantity;
      if (Project.LimitConsumeTimes == 0) {
        return num1;
      } else {
        return num2 <= num1 ? num2 : num1;
      }
    },
    /**  通用次卡 删除   */
    removeSelectGeneralItemClick(index, ProjectIndex) {
      var that = this;
      that.currentSelectGeneralCardList[index].Projects.splice(ProjectIndex, 1);
      if (that.currentSelectGeneralCardList[index].Projects.length <= 0) {
        that.currentSelectGeneralCardList.splice(index, 1);
      }
    },

    /** 套餐卡 ======================================================================  */
    // 套餐卡项目消耗
    consumePackageProjectClick(item) {
      var that = this;
      if (item.ValidBalance == 0) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }
      var selectPack = Enumerable.from(that.currentselectPackageCardList)
        .where(function(item) {
          return item.ID == that.currentSelectPackageItem.ID;
        })
        .toArray()[0];
      var Project = {
        Name: item.Name,
        Alias: item.Alias,
        ValidBalance: item.ValidBalance,
        ID: item.ID,
        ProjectID: item.ProjectID,
        Quantity: 1,
        Price: item.Price,
        UnitAmount: item.Amount,
        TotalAmount: item.Amount,
        IsLargess: item.IsLargess,
        max: item.ValidBalance,
        ProjectTreatHandler: [],
        HandleTypeList: [],
      };
      that.projectTreatHandlers.forEach((item) => {
        Project.HandleTypeList.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });
      if (typeof selectPack == "undefined") {
        that.currentselectPackageCardList.push({
          ID: that.currentSelectPackageItem.ID,
          PackageCardID: that.currentSelectPackageItem.PackageCardID,
          Name: that.currentSelectPackageItem.Name,
          Alias: that.currentSelectPackageItem.Alias,
          IsLargess: that.currentSelectPackageItem.IsLargess,
          packageProjectList: [Project],
          packageProductList: [],
          packageSavingCardList: [],
          packageTimeCardList: [],
          packageGeneralList: [],
        });
      } else {
        if (item.ValidBalance <= that.getPackageProjectConsumeQuantity(selectPack, item.ID)) {
          that.$message.error({
            message: "有效次数不足",
            duration: 2000,
          });
          return;
        }
        selectPack.packageProjectList.push(Project);
      }
    },

    //套餐卡储值卡消耗 c存量列表的点击
    consumePackageSavingCardClick(packageItem, item) {
      var that = this;
      that.isConsumePackage = true;
      that.tempCurrentSelectPackageItem = that.currentSelectPackageItem;
      if (item.ValidBalance == 0) {
        that.$message.error({
          message: "储值卡有效余额为0，暂不可消耗。请选择其他储值卡",
          duration: 2000,
        });
        return;
      }
      that.isConsumePackage = true;
      that.selectSavingcardItem = item;
      that.currentSavingcardProjectCategoty = [];
      that.currentSavingcardProject = [];
      that.searchSavingcardProjectName = "";
      that.savingCardConsumeItem = null;

      var packItem = Enumerable.from(that.currentselectPackageCardList).firstOrDefault((i) => i.ID == packageItem.ID);
      if (typeof packItem != "undefined") {
        that.savingCardConsumeItem = Enumerable.from(packItem.packageSavingCardList).firstOrDefault((i) => i.ID == item.ID);
      }
      that.treatSavingCardAccountProjectNet(true);
    },
    /**  已选列表的 点击  */
    consumePackageSavingCardSelectClick(packageItem, item) {
      var that = this;
      that.isConsumePackage = true;
      that.tempCurrentSelectPackageItem = item;
      if (item.ValidBalance == 0) {
        that.$message.error({
          message: "储值卡有效余额为0，暂不可消耗。请选择其他储值卡",
          duration: 2000,
        });
        return;
      }
      that.isConsumePackage = true;
      that.selectSavingcardItem = item;
      that.currentSavingcardProjectCategoty = [];
      that.currentSavingcardProject = [];
      that.searchSavingcardProjectName = "";
      that.savingCardConsumeItem = null;

      var packItem = Enumerable.from(that.currentselectPackageCardList).firstOrDefault((i) => i.ID == packageItem.ID);
      if (typeof packItem != "undefined") {
        that.savingCardConsumeItem = Enumerable.from(packItem.packageSavingCardList).firstOrDefault((i) => i.ID == item.ID);
      }
      that.treatSavingCardAccountProjectNet(true);
    },

    /**  套餐卡 储值卡 弹窗 点击项目  */
    addPackageItemSavingCardProject(item) {
      var that = this;
      var consumeAmount_1 = that.getCurrentSavingCardConsumeAmount(that.savingCardConsumeItem, that.isConsumePackage); //将要消耗的金额
      var ValidBalance_1 = that.selectSavingcardItem.ValidBalance; // 当前储值卡的有效余额
      if (Number(ValidBalance_1) == Number(consumeAmount_1)) {
        that.$message.error({
          message: "储值卡余额不足。请选择其他储值卡",
          duration: 2000,
        });
        return;
      }
      var tempConsumeAmount_1 = Number(consumeAmount_1) + Number(item.PreferentialPrice);
      /**  判断 将要消耗的金额是否大于储值卡的有效余额  */
      if (tempConsumeAmount_1 > ValidBalance_1) {
        if (that.TreatPermission.ModifyPrices_TreatSavingCard) {
          var tempPrice_1 = ValidBalance_1 - consumeAmount_1;
          var tempStr_1 = "当前可用余额为 " + tempPrice_1.toFixed(2) + " 元，此项目需要消耗" + Number(item.PreferentialPrice).toFixed(2) + "，如果需要继续操作，此项目的耗卡金额将修改为 " + tempPrice_1.toFixed(2) + " 元";
          that
            .$confirm(tempStr_1, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              that.judgePackageSavingCardConsumeProject(item, tempPrice_1);
              that.dialogSavingCard = false;
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          that.$message.error({
            message: "卡剩余金额不足，请更换其他卡",
            duration: 2000,
          });
        }
      } else {
        that.judgePackageSavingCardConsumeProject(item, item.PreferentialPrice);
        that.dialogSavingCard = false;
      }
    },

    /**  套餐卡中的储值卡  */
    judgePackageSavingCardConsumeProject(project, savingAmount) {
      /**  消耗的项目  */
      var that = this;
      var PerformanceRatio = ((savingAmount / project.PreferentialPrice) * 100).toFixed(2);
      var packSavingChildItem = {
        Name: project.Name,
        Alias: project.Alias,
        ID: project.ID,
        Quantity: 1,
        Price: project.Price,
        UnitAmount: project.PreferentialPrice,
        TotalAmount: savingAmount,
        PreferentialPrice: project.PreferentialPrice,
        CardPreferentialAmount: project.CardPreferentialAmount, //优惠价格
        CardPreferentialAmountTotal: project.CardPreferentialAmount, //优惠价格
        PopoveVisible: false,
        PopoveAmount: savingAmount,
        PerformanceRatio: Number(PerformanceRatio),
        PricePreferentialAmount: project.PreferentialPrice - savingAmount, //手动改价优惠
        SavingCardTreatHandler: [],
        HandleTypeList: [],
      };

      that.savingCardTreatHandlers.forEach((item) => {
        packSavingChildItem.HandleTypeList.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      var packSavingItem = null;
      var packItem = null;
      that.currentselectPackageCardList.forEach((item) => {
        if (item.ID == that.currentSelectPackageItem.ID) {
          packItem = item;
          item.packageSavingCardList.forEach((childItem) => {
            if (childItem.ID == that.selectSavingcardItem.ID) {
              packSavingItem = childItem;
              that.savingCardConsumeItem = packSavingItem;
            }
          });
        }
      });

      if (packItem == null) {
        packSavingItem = {
          ID: that.selectSavingcardItem.ID,
          SavingCardID: that.selectSavingcardItem.SavingCardID,
          AccountID: that.selectSavingcardItem.AccountID,
          Type: that.selectSavingcardItem.Type,
          Name: that.selectSavingcardItem.Name,
          Alias: that.selectSavingcardItem.Alias,
          IsLargess: that.selectSavingcardItem.IsLargess,
          ValidBalance: that.selectSavingcardItem.ValidBalance,
          SavingCardItem: that.selectSavingcardItem,
          Projects: [packSavingChildItem],
        };
        that.savingCardConsumeItem = packSavingItem;
        packItem = {
          ID: that.currentSelectPackageItem.ID,
          PackageCardID: that.currentSelectPackageItem.PackageCardID,
          Name: that.currentSelectPackageItem.Name,
          Alias: that.currentSelectPackageItem.Alias,
          IsLargess: that.currentSelectPackageItem.IsLargess,
          packageProjectList: [],
          packageProductList: [],
          packageSavingCardList: [packSavingItem],
          packageTimeCardList: [],
          packageGeneralList: [],
        };
        that.currentselectPackageCardList.push(packItem);
      } else {
        if (packSavingItem == null) {
          packSavingItem = {
            ID: that.selectSavingcardItem.ID,
            SavingCardID: that.selectSavingcardItem.SavingCardID,
            AccountID: that.selectSavingcardItem.AccountID,
            Type: that.selectSavingcardItem.Type,
            Name: that.selectSavingcardItem.Name,
            ValidBalance: that.selectSavingcardItem.ValidBalance,
            Projects: [packSavingChildItem],
          };
          that.savingCardConsumeItem = packSavingItem;
          packItem.packageSavingCardList.push(packSavingItem);
        } else {
          packSavingItem.Projects.push(packSavingChildItem);
        }
      }
    },

    // 套餐卡消耗时效卡
    consumePackageTimeCardClick(packItem, item) {
      var that = this;
      that.isConsumePackage = true;
      that.tempCurrentSelectPackageItem = that.currentSelectPackageItem;
      if (item.ConsumeCycle == 0 || item.CycleLimitAmount == 0) {
        /**  如果 消耗周期 或者 周期次数 为零 则消耗不做限制  */
        that.selectTimecardItem = item;
        that.currentTimeCardProjectCategoty = [];
        that.currentTimeCardProject = [];
        that.searchTimeCardPorjectName = "";
        that.treatTimeCardAccountProjectNet(true, item);
      } else {
        var ValidBalance = item.CycleLimitAmount - item.ConsumeCycleAmount; //周期次数 减去周期内已耗用次数 等于有效次数
        if (ValidBalance <= 0) {
          that.$message.error({
            message: "周期内消耗次数不足",
            duration: 2000,
          });
          return;
        }
        that.selectTimecardItem = item;
        that.currentTimeCardProjectCategoty = [];
        that.currentTimeCardProject = [];
        that.searchTimeCardPorjectName = "";
        that.treatTimeCardAccountProjectNet(true, item);
      }
    },

    // 套餐卡消耗时效 已选列表卡
    consumePackageTimeCardSelectClick(packItem, item) {
      var that = this;
      that.isConsumePackage = true;
      that.tempCurrentSelectPackageItem = packItem;

      if (item.ConsumeCycle == 0 || item.CycleLimitAmount == 0) {
        /**  如果 消耗周期 或者 周期次数 为零 则消耗不做限制  */
        that.selectTimecardItem = item;
        that.currentTimeCardProjectCategoty = [];
        that.currentTimeCardProject = [];
        that.searchTimeCardPorjectName = "";
        that.treatTimeCardAccountProjectNet(true, item);
      } else {
        var ValidBalance = item.CycleLimitAmount - item.ConsumeCycleAmount; //周期次数 减去周期内已耗用次数 等于有效次数
        if (ValidBalance <= 0) {
          that.$message.error({
            message: "周期内消耗次数不足",
            duration: 2000,
          });
          return;
        }
        that.selectTimecardItem = item;
        that.currentTimeCardProjectCategoty = [];
        that.currentTimeCardProject = [];
        that.searchTimeCardPorjectName = "";
        that.treatTimeCardAccountProjectNet(true, item);
      }
    },

    /** 套餐卡 中时效卡点击项目   */
    addPackageTimeCardProjectClick(item) {
      var that = this;
      /**  周期内有效的耗用次数  */
      var CycleValidBalance = that.selectTimecardItem.CycleLimitAmount - that.selectTimecardItem.ConsumeCycleAmount;
      /**  已耗用的次数  */
      var curAmount = that.getPackageTimeCardConsumAmount(that.tempCurrentSelectPackageItem.ID, that.selectTimecardItem.ID);

      /**  如果 消耗周期 与周期次数任意为 0 切在不欠款的情况下不限制消耗次数  */
      if (that.selectTimecardItem.CycleLimitAmount != 0 && that.selectTimecardItem.ConsumeCycle != 0) {
        if (curAmount >= CycleValidBalance) {
          /**  判断周期次数  */
          that.$message.error({
            message: "周期内有效次数不足",
            duration: 2000,
          });
          return;
        }
      }

      /**   如果时效卡有欠款 则根据有效次数限制消耗次数 */
      // if (that.selectTimecardItem.ArrearAmount > 0) {
      //   //有欠款
      //   if (curAmount > that.selectTimecardItem.ValidBalance) {
      //     /**  当前消耗次数是否大于 有效次数  */

      //     that.$message.error({
      //       message:
      //         "当前时效卡存在欠款，累计消耗次数不能超过" +
      //         that.selectTimecardItem.ValidBalance +
      //         "次",
      //       duration: 2000,
      //     });
      //     return;
      //   }
      // }
      /**  当前业绩金额  */
      var currentTimeAmount = that.selectTimecardItem.Amount;
      /**  如果 消耗次数大于 等于 剩余业绩次数 则消耗金额为零  */
      if (curAmount >= that.selectTimecardItem.PerformanceBalance) {
        currentTimeAmount = 0;
      }

      var tempHandle = [];
      Enumerable.from(that.projectTreatHandlers).forEach((item) => {
        tempHandle.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      var Project = {
        Name: item.Name,
        Alias: item.Alias,
        ID: item.ID,
        Quantity: 1, //数量
        IsLargess: that.selectTimecardItem.IsLargess,
        Price: item.Price, // 项目单价
        UnitAmount: that.selectTimecardItem.Amount, //  消耗参考单价
        TotalAmount: currentTimeAmount, // 消耗 业绩金额
        TimeCardTreatHandler: [],
        HandleTypeList: tempHandle,
        TargetWeight: item.TargetWeight || "",
        CustomerTargetWeight: item.CustomerTargetWeight || "",
        MinWeight: item.MinWeight || "",
        SaleCurrentWeight: item.SaleCurrentWeight || "",
      };

      var timeCardItem = {
        ID: that.selectTimecardItem.ID,
        TimeCardID: that.selectTimecardItem.TimeCardID,
        Name: that.selectTimecardItem.Name,
        Alias: that.selectTimecardItem.Alias,
        IsLargess: that.selectTimecardItem.IsLargess,
        ArrearAmount: that.selectTimecardItem.ArrearAmount,
        Amount: that.selectTimecardItem.Amount,
        UnitAmount: that.selectTimecardItem.Amount,
        CycleLimitAmount: that.selectTimecardItem.CycleLimitAmount, // 周期内次数
        ConsumeCycle: that.selectTimecardItem.ConsumeCycle,
        ConsumeCycleAmount: that.selectTimecardItem.ConsumeCycleAmount, // 周期内已消耗次数
        ValidBalance: that.selectTimecardItem.ValidBalance, //周期内有效次数
        PerformanceTimes: that.selectTimecardItem.PerformanceTimes, // 总业绩次数
        PerformanceBalance: that.selectTimecardItem.PerformanceBalance, // 剩余业绩次数
        TimeCardItem: that.selectTimecardItem,
        Projects: [Project],
      };

      var PackageItem = Enumerable.from(that.currentselectPackageCardList).firstOrDefault((i) => i.ID == that.tempCurrentSelectPackageItem.ID);
      if (typeof PackageItem == "undefined") {
        that.currentselectPackageCardList.push({
          ID: that.tempCurrentSelectPackageItem.ID,
          PackageCardID: that.tempCurrentSelectPackageItem.PackageCardID,
          Name: that.tempCurrentSelectPackageItem.Name,
          Alias: that.tempCurrentSelectPackageItem.Alias,
          IsLargess: that.tempCurrentSelectPackageItem.IsLargess,
          PerformanceBalance: that.selectTimecardItem.PerformanceBalance,
          packageProjectList: [],
          packageProductList: [],
          packageSavingCardList: [],
          packageTimeCardList: [timeCardItem],
          packageGeneralList: [],
        });
      } else {
        var timeCard = Enumerable.from(PackageItem.packageTimeCardList).firstOrDefault((i) => i.ID == that.selectTimecardItem.ID);
        if (typeof timeCard == "undefined") {
          PackageItem.packageTimeCardList.push(timeCardItem);
        } else {
          if (timeCard.Projects.length > 0) {
            that.$message.warning("每张时效卡只可以选择一项服务");
            return;
          }
          timeCard.Projects.push(Project);
        }
      }
      that.dialogTimeCard = false;
    },

    /**======================================================================  */
    // 套餐卡消耗通用次卡
    consumePackageGeneralCardClick(item) {
      var that = this;
      that.isConsumePackage = true;
      that.tempCurrentSelectPackageItem = that.currentSelectPackageItem;
      that.selectGeneralcardItem = item;
      that.currentGeneralcarProjectCategoty = [];
      that.currentGeneralcarProject = [];
      that.currentGeneralcarActiveName = [];
      that.searchGeneralcarPorjectName = "";
      that.treatGeneralCardAccountProjectNet(true);
    },
    /**  已选 套餐卡中 通用次卡点击事件  */
    consumePackageGeneralCardSelectClick(packItem, item) {
      var that = this;
      that.isConsumePackage = true;
      that.tempCurrentSelectPackageItem = packItem;
      that.selectGeneralcardItem = item;
      that.currentGeneralcarProjectCategoty = [];
      that.currentGeneralcarProject = [];
      that.currentGeneralcarActiveName = [];
      that.searchGeneralcarPorjectName = "";
      that.treatGeneralCardAccountProjectNet(true);
    },

    /** 套餐卡中通用次卡点击 项目   */
    addPackageGeneralCardProjectClick(item) {
      var that = this;
      if (that.selectGeneralcardItem.ValidBalance < that.getGeneralCardQuantity(that.selectGeneralcardItem.ID) + item.ConsumeAmount) {
        that.$message.error({
          message: "通用次卡次数不足",
          duration: 2000,
        });
        return;
      }
      let beforeConsumeTimes = that.getPackageGeneralCardProQua(that.tempCurrentSelectPackageItem.ID, that.selectGeneralcardItem.ID, item.ID);
      if (item.LimitConsumeTimes != 0 && item.LimitConsumeTimes - item.Quantity < beforeConsumeTimes + item.ConsumeAmount) {
        that.$message.error({
          message: "该项目以达到最大可消耗次数",
          duration: 2000,
        });
        return;
      }

      /**  通用次卡使用项目信息  */
      var Project = {
        Name: item.Name,
        Alias: item.Alias,
        Price: item.Price,
        ID: item.ID,
        Quantity: 1,
        UnitAmount: that.selectGeneralcardItem.Amount,
        TotalAmount: that.selectGeneralcardItem.Amount * item.ConsumeAmount,
        ConsumeAmount: item.ConsumeAmount,
        ProjectTreatHandler: [],
        HandleTypeList: [],
        LimitConsumeTimes: item.LimitConsumeTimes,
        beforeQuantity: item.Quantity,
      };

      Enumerable.from(that.projectTreatHandlers).forEach((item) => {
        Project.HandleTypeList.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      var generalCard = {
        ID: that.selectGeneralcardItem.ID,
        GeneralCardID: that.selectGeneralcardItem.GeneralCardID,
        Name: that.selectGeneralcardItem.Name,
        Alias: that.selectGeneralcardItem.Alias,
        ValidBalance: that.selectGeneralcardItem.ValidBalance,
        Price: that.selectGeneralcardItem.Price,
        Amount: that.selectGeneralcardItem.Amount,
        IsLargess: that.selectGeneralcardItem.IsLargess,
        ArrearAmount: that.selectGeneralcardItem.ArrearAmount,
        GeneralCardItem: that.selectGeneralcardItem,
        Projects: [Project],
      };

      var packageItem = Enumerable.from(that.currentselectPackageCardList).firstOrDefault((i) => i.ID == that.tempCurrentSelectPackageItem.ID);
      if (typeof packageItem == "undefined") {
        that.currentselectPackageCardList.push({
          ID: that.tempCurrentSelectPackageItem.ID,
          PackageCardID: that.tempCurrentSelectPackageItem.PackageCardID,
          Name: that.tempCurrentSelectPackageItem.Name,
          Alias: that.tempCurrentSelectPackageItem.Alias,
          IsLargess: that.tempCurrentSelectPackageItem.IsLargess,
          PerformanceBalance: that.selectTimecardItem.PerformanceBalance,
          packageProjectList: [],
          packageProductList: [],
          packageSavingCardList: [],
          packageTimeCardList: [],
          packageGeneralList: [generalCard],
        });
      } else {
        var generalCardSelect = Enumerable.from(packageItem.packageGeneralList).firstOrDefault((i) => i.ID == that.selectGeneralcardItem.ID);
        if (typeof generalCardSelect == "undefined") {
          packageItem.packageGeneralList.push(generalCard);
        } else {
          generalCardSelect.Projects.push(Project);
        }
      }
      that.dialogGeneralCard = false;
    },
    // 当前套餐看通用次卡 项目消耗次数
    getPackageGeneralCardProQua(packageCardID, generalID, ProID) {
      var that = this;
      if (!generalID || !packageCardID) return 0;
      let packageCardItem = Enumerable.from(that.currentselectPackageCardList).firstOrDefault((i) => {
        return i.ID == packageCardID;
      }, -1);
      if (packageCardItem === -1) return 0;
      var generalItem = Enumerable.from(packageCardItem.packageGeneralList).firstOrDefault((i) => {
        return i.ID == generalID;
      }, -1);
      if (generalItem === -1) return 0;
      return Enumerable.from(generalItem.Projects).sum((i) => {
        if (i.ID == ProID) {
          return Number(Number(i.Quantity) * Number(i.ConsumeAmount));
        } else {
          return 0;
        }
      });
    },

    /**======================================================================  */
    // 套餐卡消耗产品
    consumePackageProductClick(item) {
      var that = this;
      if (item.ValidBalance == 0) {
        that.$message.error({
          message: "有效次数不足",
          duration: 2000,
        });
        return;
      }
      var selectPack = Enumerable.from(that.currentselectPackageCardList)
        .where(function(item) {
          return item.ID == that.currentSelectPackageItem.ID;
        })
        .toArray()[0];
      var Product = {
        Name: item.Name,
        Alias: item.Alias,
        ValidBalance: item.ValidBalance,
        ID: item.ID,
        ProductID: item.ProductID,
        Quantity: 1,
        Price: item.Price,
        UnitAmount: item.Amount,
        TotalAmount: item.Amount,
        IsLargess: item.IsLargess,
        ProjectTreatHandler: [],
        HandleTypeList: [],
      };

      that.productTreatHandlers.forEach((item) => {
        Product.HandleTypeList.push({
          Name: item.Name,
          ID: item.ID,
          Employee: [],
        });
      });

      if (typeof selectPack == "undefined") {
        that.currentselectPackageCardList.push({
          ID: that.currentSelectPackageItem.ID,
          PackageCardID: that.currentSelectPackageItem.PackageCardID,
          Name: that.currentSelectPackageItem.Name,
          Alias: that.currentSelectPackageItem.Alias,
          IsLargess: that.currentSelectPackageItem.IsLargess,
          packageProjectList: [],
          packageProductList: [Product],
          packageSavingCardList: [],
          packageTimeCardList: [],
          packageGeneralList: [],
        });
      } else {
        if (item.ValidBalance <= that.getPackageProductConsumeQuantity(selectPack, item.ID)) {
          that.$message.error({
            message: "有效次数不足",
            duration: 2000,
          });
          return;
        }
        selectPack.packageProductList.push(Product);
      }
    },

    /**  点击套餐卡分类  */
    clickPackageCagegory: function(item, index) {
      var that = this;
      if (index != that.currentPackCategoryIndex) {
        that.currentSelectPackageItem = item;
        that.currentPackCategoryIndex = index;
        that.treatPackageCardAccountDetailsNet();
      }
    },

    /**  套餐卡中项目的消耗次数  */
    getPackageProjectConsumeQuantity(packageItem, ProjectID) {
      if (!packageItem) return 0;
      var Amount = 0;
      packageItem.packageProjectList.forEach((Project) => {
        if (Project.ID == ProjectID) {
          Amount += Number(Project.Quantity);
        }
      });
      return Amount;
    },
    /**  套餐卡中项目的消耗次数  */
    getPackageProductConsumeQuantity(packageItem, ProductID) {
      if (!packageItem) return 0;
      var Amount = 0;
      packageItem.packageProductList.forEach((Product) => {
        if (Product.ID == ProductID) {
          Amount += Number(Product.Quantity);
        }
      });
      return Amount;
    },

    /**  套餐卡储值卡金额  */
    getPackageSavingCardConsumeAmount(packageItem, SavingCardID) {
      if (!packageItem) return 0;
      var savingCard = Enumerable.from(packageItem.packageSavingCardList).firstOrDefault((i) => i.ID == SavingCardID);
      if (typeof savingCard == "undefined") return 0;
      var amount = Enumerable.from(savingCard.Projects).sum((p) => Number(p.TotalAmount));
      return amount;
    },

    /**  套餐卡时效卡次数  */
    getPackageTimeCardConsumAmount(packageItemID, timeCardID) {
      if (!packageItemID) return 0;
      var Amount = 0;
      var that = this;
      that.currentselectPackageCardList.forEach((packageItem) => {
        if (packageItemID == packageItem.ID) {
          packageItem.packageTimeCardList.forEach((timeCard) => {
            if (timeCardID == timeCard.ID) {
              timeCard.Projects.forEach((Project) => {
                Amount += Number(Project.Quantity);
              });
            }
          });
        }
      });
      return Amount;
    },
    /**  套餐卡通用次卡次数  */
    getPackageGeneralCardConsumAmount(packageID, generalCardID) {
      if (!packageID) return 0;
      var packageItem = Enumerable.from(this.currentselectPackageCardList).firstOrDefault((i) => i.ID == packageID);

      if (typeof packageItem == "undefined") return 0;
      var generalCard = Enumerable.from(packageItem.packageGeneralList).firstOrDefault((i) => i.ID == generalCardID);
      if (typeof generalCard == "undefined") return 0;
      return Enumerable.from(generalCard.Projects).sum((i) => Number(Number(i.Quantity) * Number(i.ConsumeAmount)));
    },

    /**  套餐卡中 通用次卡的最大数量  */
    getPackageGeneralCardProjectMaxQuantity(packageItem, generalCard, Project) {
      var that = this;
      let times = Math.floor((generalCard.ValidBalance - that.getPackageGeneralCardConsumAmount(packageItem.ID, generalCard.ID)) / Project.ConsumeAmount) + Project.Quantity;
      if (Project.LimitConsumeTimes == 0) {
        return times;
      }
      return Project.LimitConsumeTimes - Project.beforeQuantity > times ? times : Project.LimitConsumeTimes - Project.beforeQuantity;
    },

    /**  套餐卡中 项目 数量更改  */
    packageProjectQuantityChangeClick(item) {
      item.TotalAmount = Number(parseFloat(item.UnitAmount * item.Quantity).toFixed(2));
    },
    /**  套餐卡中的产品  数量更改  */
    packageProductQuantityChangeClick(item) {
      item.TotalAmount = parseFloat(item.UnitAmount * item.Quantity).toFixed(2);
    },

    /** 删除套餐卡中的 储值卡 */
    removePackageSavingCardItemClick(packItem, SavingCard, Project, index, SavingCardIndex, ProjectIndex) {
      var that = this;
      SavingCard.Projects.splice(ProjectIndex, 1);
      if (SavingCard.Projects.length <= 0) {
        packItem.packageSavingCardList.splice(SavingCardIndex, 1);
      }

      if (packItem.packageProjectList.length == 0 && packItem.packageProductList.length == 0 && packItem.packageSavingCardList.length == 0 && packItem.packageTimeCardList.length == 0 && packItem.packageGeneralList.length == 0) {
        that.currentselectPackageCardList.splice(index, 1);
      }
    },
    /** 删除套餐卡中的 时效卡 */
    removePackageTimeCardItemClick(packItem, timeCard, Project, index, timeCardIndex, ProjectIndex) {
      var that = this;
      timeCard.Projects.splice(ProjectIndex, 1);
      if (timeCard.Projects.length == 0) {
        packItem.packageTimeCardList.splice(timeCardIndex, 1);
      } else {
        that.timeCardRemoveAllOcationValidQuantity(timeCard);
      }

      if (packItem.packageProjectList.length == 0 && packItem.packageProductList.length == 0 && packItem.packageSavingCardList.length == 0 && packItem.packageTimeCardList.length == 0 && packItem.packageGeneralList.length == 0) {
        that.currentselectPackageCardList.splice(index, 1);
      }
    },

    /**  删除套餐卡中的通用次卡  */
    removePackageGeneralCardItemClick(packItem, generalCard, Project, index, generalCardIndex, ProjectIndex) {
      var that = this;
      generalCard.Projects.splice(ProjectIndex, 1);
      if (generalCard.Projects.length <= 0) {
        packItem.packageGeneralList.splice(generalCardIndex, 1);
      }

      if (packItem.packageProjectList.length == 0 && packItem.packageProductList.length == 0 && packItem.packageSavingCardList.length == 0 && packItem.packageTimeCardList.length == 0 && packItem.packageGeneralList.length == 0) {
        that.currentselectPackageCardList.splice(index, 1);
      }
    },

    /** 删除套餐卡中的 储值卡 时效卡 通用次卡   */
    removeSelectPackageCardItemClick(index, childIndex, childIndex_1) {
      var that = this;
      that.currentselectPackageCardList[index].ChildItems[childIndex].ChildItems.splice(childIndex_1, 1);
      if (that.currentselectPackageCardList[index].ChildItems[childIndex].ChildItems.length <= 0) {
        that.currentselectPackageCardList[index].ChildItems.splice(childIndex, 1);
      }
      if (that.currentselectPackageCardList[index].ChildItems.length <= 0) {
        that.currentselectPackageCardList.splice(index, 1);
      }
    },
    /**  删除套餐卡中的项目  */
    removePackageProjectItemClick(packItem, index, childIndex) {
      var that = this;
      packItem.packageProjectList.splice(childIndex, 1);
      if (packItem.packageProjectList.length == 0 && packItem.packageProductList.length == 0 && packItem.packageSavingCardList.length == 0 && packItem.packageTimeCardList.length == 0 && packItem.packageGeneralList.length == 0) {
        that.currentselectPackageCardList.splice(index, 1);
      }
    },
    /**  删除套餐卡中的 产品  */
    removePackageProductItemClick(packItem, index, childIndex) {
      var that = this;
      packItem.packageProductList.splice(childIndex, 1);
      if (packItem.packageProjectList.length == 0 && packItem.packageProductList.length == 0 && packItem.packageSavingCardList.length == 0 && packItem.packageTimeCardList.length == 0 && packItem.packageGeneralList.length == 0) {
        that.currentselectPackageCardList.splice(index, 1);
      }
    },
    /**  套餐卡 中 储值卡修改数量   */
    packageSavingCartItemQuantityChangeClick(currentValue, oldValue, packageItem, savingCard, Project) {
      var that = this;

      var savingCardAmount = that.getPackageSavingCardConsumeAmount(packageItem, savingCard.ID); //  当前 消耗金额
      var curAmount = Project.Quantity * Project.PreferentialPrice + savingCardAmount - Project.PreferentialPrice * oldValue;
      // if(Number(item.ValidBalance) < (Number(consumeAmount) - Number(project.TotalAmount) + Number(project.PreferentialPrice * project.Quantity))){
      if (Number(savingCard.ValidBalance) < Number(savingCardAmount) - Project.TotalAmount + Number(Project.PreferentialPrice * Project.Quantity)) {
        that.$message.error({
          message: "有效余额不足",
          duration: 2000,
        });
        that.$nextTick(() => {
          Project.Quantity = oldValue;
        });

        return;
      }

      if (savingCard.ValidBalance < curAmount) {
        that.$message.error({
          message: "有效余额不足",
          duration: 2000,
        });

        var MaxQuantity = Math.floor((savingCard.ValidBalance - savingCardAmount + Number(Project.TotalAmount)) / Project.PreferentialPrice);
        this.$nextTick(() => {
          Project.Quantity = MaxQuantity;
          Project.TotalAmount = parseFloat(Project.Quantity * Project.PreferentialPrice).toFixed(2);

          Project.CardPreferentialAmountTotal = parseFloat(Project.CardPreferentialAmount * Project.Quantity).toFixed(2);
          Project.PopoveAmount = Project.TotalAmount;
        });
      } else {
        Project.PerformanceRatio = 100;
        Project.TotalAmount = parseFloat(Project.Quantity * Project.PreferentialPrice).toFixed(2);
        Project.PopoveAmount = Project.TotalAmount;
        Project.CardPreferentialAmountTotal = parseFloat(Project.CardPreferentialAmount * Project.Quantity).toFixed(2);
      }
    },
    /**  套餐卡修改通用次卡数量  */
    packageGeneralCartItemQuantityChangeClick(currentValue, oldValue, item, generalCard, Project) {
      Project.TotalAmount = parseFloat((Project.UnitAmount * Project.Quantity * Project.ConsumeAmount).toFixed(2));

      // Project.Amount = (generalCard.Amount * Project.Quantity * Project.ConsumeAmount).toFixed(2);
    },
    /**  套餐卡 时效卡修改数量  */
    packageTimeCartItemQuantityChangeClick(currentValue, oldValue, item, timeCard, Project) {
      var that = this;
      var curVailAmount = that.getTimeCardConsumePerformanceAmount(timeCard, Project);
      if (curVailAmount > 0) {
        if (Project.Quantity >= curVailAmount) {
          Project.TotalAmount = parseFloat(Project.UnitAmount * curVailAmount).toFixed(2);
        } else {
          Project.TotalAmount = parseFloat(Project.UnitAmount * Project.Quantity).toFixed(2);
          that.timeCardAllocationValidQuantity(timeCard, Project, curVailAmount);
        }
      }
    },

    /**   其他  */
    // 锚点
    navChange: function(index, selector) {
      var that = this;
      that.navIndex = index;
      var anchor = this.$el.querySelector(selector);
      that.$el.querySelector(".el_scrollbar_project").scrollTop = anchor.offsetTop - 50;
    },

    // 结账
    consumeBillClick: function() {
      var that = this;
      console.log(that.currentSelectTimeCardList);
      if ((that.currentSelectProjectList.length <= 0) & (that.currentSelectProductList.length <= 0) & (that.currentSelectTimeCardList.length <= 0) & (that.currentselectPackageCardList.length <= 0) & (that.currentSelectGeneralCardList.length <= 0) & (that.currentSelectSavingCardList.length <= 0)) {
        that.$message.error("请选择商品！");
        return;
      }
      let TimeCardBool = true;
      if (that.currentSelectTimeCardList.length > 0) {
        that.currentSelectTimeCardList.forEach((item) => {
          item.Projects.forEach((card) => {
            if (card.TargetWeight && !card.CurrentWeight) {
              that.$message.error("请填写时效卡当前体重");
              TimeCardBool = false;
            }
          });
        });
      }
      if (!TimeCardBool) return;
      let PackageCardBool = true;
      if (that.currentselectPackageCardList.length > 0) {
        that.currentselectPackageCardList.forEach((item) => {
          item.packageTimeCardList.forEach((ItemPack) => {
            ItemPack.Projects.forEach((timecard) => {
              if (timecard.TargetWeight && !timecard.CurrentWeight) {
                that.$message.error("请填写套餐卡所属时效卡当前体重");
                PackageCardBool = false;
              }
            });
          });
        });
      }
      if (!PackageCardBool) return;
      if (that.getBillDate() == null) {
        that.$message.error("请输入补录日期");
        return;
      }
      that.dialogBill = true;
    },
    /**  提交 消耗订单  */
    submitConsumeOrderClick: function() {
      var that = this;
      that.dialogBill = false;
      that.treatBillCreateNet(that.getTreatParams());
    },

    getTreatParams() {
      let that = this;
      let treatParams = {};
      treatParams.BillDate = that.getBillDate();
      treatParams.CustomerID = that.customerID;
      treatParams.Remark = that.remark;
      treatParams.Amount = parseFloat(that.totalAmount).toFixed(2);
      treatParams.Project = [];
      treatParams.Product = [];
      treatParams.SavingCard = [];
      treatParams.TimeCard = [];
      treatParams.GeneralCard = [];
      Enumerable.from(that.currentSelectProjectList).forEach((item) => {
        var handle = [];
        item.HandleTypeList.forEach((handleType) => {
          handleType.Employee.forEach((hand) => {
            handle.push({
              TreatHandlerID: handleType.ID,
              EmployeeID: hand.EmployeeID,
              Scale: hand.Discount,
            });
          });
        });
        treatParams.Project.push({
          ProjectAccountID: item.ID,
          ProjectID: item.ProjectID,
          Quantity: item.Quantity,
          Price: item.Price,
          Amount: item.UnitAmount,
          TotalAmount: item.TotalAmount,
          IsLargess: item.IsLargess,
          ProjectTreatHandler: handle,
        });
      });

      /**  产品  */
      that.currentSelectProductList.forEach((item) => {
        var handle = [];
        item.HandleTypeList.forEach((handleType) => {
          handleType.Employee.forEach((hand) => {
            handle.push({
              TreatHandlerID: handleType.ID,
              EmployeeID: hand.EmployeeID,
              Scale: hand.Discount,
            });
          });
        });
        treatParams.Product.push({
          ProductAccountID: item.ID,
          ProductID: item.ProductID,
          Quantity: item.Quantity,
          Price: item.Price,
          Amount: item.UnitAmount,
          TotalAmount: item.TotalAmount.toFixed(2),
          IsLargess: item.IsLargess,
          ProductTreatHandler: handle,
        });
      });
      /**  储值卡  */
      that.currentSelectSavingCardList.forEach((item) => {
        item.Projects.forEach((Project) => {
          var handle = [];
          Project.HandleTypeList.forEach((handleType) => {
            handleType.Employee.forEach((hand) => {
              handle.push({
                TreatHandlerID: handleType.ID,
                EmployeeID: hand.EmployeeID,
                Scale: hand.Discount,
              });
            });
          });
          treatParams.SavingCard.push({
            ID: item.ID,
            Type: item.Type,
            SavingCardAccountID: item.AccountID,
            ProjectID: Project.ID,
            Quantity: Project.Quantity,
            Price: Project.Price,
            TotalAmount: Project.TotalAmount,
            CardPreferentialAmount: Project.CardPreferentialAmountTotal,
            PricePreferentialAmount: parseFloat(Project.PricePreferentialAmount).toFixed(2),
            SavingCardTreatHandler: handle,
          });
        });
      });
      /**  时效卡  */
      that.currentSelectTimeCardList.forEach((item) => {
        item.Projects.forEach((Project) => {
          var handle = [];
          Project.HandleTypeList.forEach((handleType) => {
            handleType.Employee.forEach((hand) => {
              handle.push({
                TreatHandlerID: handleType.ID,
                EmployeeID: hand.EmployeeID,
                Scale: hand.Discount,
              });
            });
          });

          treatParams.TimeCard.push({
            TimeCardAccountID: item.ID,
            ProjectID: Project.ID,
            Quantity: Project.Quantity,
            Price: Project.Price,
            Amount: Project.UnitAmount,
            TotalAmount: Project.TotalAmount,
            IsLargess: Project.IsLargess,
            TimeCardTreatHandler: handle,
            TargetWeight: Project.TargetWeight,
            CustomerTargetWeight: Project.CustomerTargetWeight,
            MinWeight: Project.MinWeight,
            SaleCurrentWeight: Project.SaleCurrentWeight,
            CurrentWeight: Project.CurrentWeight,
          });
        });
      });
      /**  通用次卡  */
      that.currentSelectGeneralCardList.forEach((item) => {
        item.Projects.forEach((Project) => {
          var handle = [];
          Project.HandleTypeList.forEach((handleType) => {
            handleType.Employee.forEach((hand) => {
              handle.push({
                TreatHandlerID: handleType.ID,
                EmployeeID: hand.EmployeeID,
                Scale: hand.Discount,
              });
            });
          });
          /**  这里的优惠金额明天确认 之后修改  */
          treatParams.GeneralCard.push({
            GeneralCardAccountID: item.ID,
            ProjectID: Project.ID,
            Quantity: Project.Quantity,
            Price: Project.Price,
            CardTreatTimes: Project.ConsumeAmount * Project.Quantity,
            Amount: Project.UnitAmount,
            TotalAmount: Project.TotalAmount,
            IsLargess: item.IsLargess,
            GeneralCardTreatHandler: handle,
          });
        });
      });

      /**  套餐卡  */
      that.currentselectPackageCardList.forEach((item) => {
        item.packageProjectList.forEach((element) => {
          var handle = [];
          element.HandleTypeList.forEach((handleType) => {
            handleType.Employee.forEach((hand) => {
              handle.push({
                TreatHandlerID: handleType.ID,
                EmployeeID: hand.EmployeeID,
                Scale: hand.Discount,
              });
            });
          });

          treatParams.Project.push({
            ProjectAccountID: element.ID,
            ProjectID: element.ProjectID,
            Quantity: element.Quantity,
            Price: element.Price,
            Amount: element.UnitAmount,
            TotalAmount: element.TotalAmount,
            IsLargess: element.IsLargess,
            ProjectTreatHandler: handle,
          });
        });

        item.packageProductList.forEach((element) => {
          var productHandle = [];
          element.HandleTypeList.forEach((handleType) => {
            handleType.Employee.forEach((hand) => {
              productHandle.push({
                TreatHandlerID: handleType.ID,
                EmployeeID: hand.EmployeeID,
                Scale: hand.Discount,
              });
            });
          });

          treatParams.Product.push({
            ProductAccountID: element.ID,
            ProductID: element.ProductID,
            Quantity: element.Quantity,
            Price: element.Price,
            Amount: element.UnitAmount,
            TotalAmount: element.TotalAmount,
            IsLargess: element.IsLargess,
            ProductTreatHandler: productHandle,
          });
        });

        item.packageSavingCardList.forEach((savingCard) => {
          savingCard.Projects.forEach((project) => {
            var handle = [];
            project.HandleTypeList.forEach((handleType) => {
              handleType.Employee.forEach((hand) => {
                handle.push({
                  TreatHandlerID: handleType.ID,
                  EmployeeID: hand.EmployeeID,
                  Scale: hand.Discount,
                });
              });
            });

            treatParams.SavingCard.push({
              ID: savingCard.ID,
              Type: savingCard.Type,
              SavingCardAccountID: savingCard.AccountID,
              ProjectID: project.ID,
              Quantity: project.Quantity,
              Price: project.Price,
              TotalAmount: project.TotalAmount,
              CardPreferentialAmount: parseFloat(project.CardPreferentialAmountTotal).toFixed(2),
              CardPreferentialAmountTotal: parseFloat(project.CardPreferentialAmount).toFixed(2),
              PricePreferentialAmount: parseFloat(project.PricePreferentialAmount).toFixed(2),
              SavingCardTreatHandler: handle,
            });
          });
        });

        item.packageTimeCardList.forEach((timeCard) => {
          timeCard.Projects.forEach((project) => {
            var handle = [];
            project.HandleTypeList.forEach((handleType) => {
              handleType.Employee.forEach((hand) => {
                handle.push({
                  TreatHandlerID: handleType.ID,
                  EmployeeID: hand.EmployeeID,
                  Scale: hand.Discount,
                });
              });
            });

            treatParams.TimeCard.push({
              TimeCardAccountID: timeCard.ID,
              ProjectID: project.ID,
              Quantity: project.Quantity,
              Price: project.Price,
              CurrentWeight: project.CurrentWeight,
              TargetWeight: project.TargetWeight,
              CustomerTargetWeight: project.CustomerTargetWeight,
              MinWeight: project.MinWeight,
              SaleCurrentWeight: project.SaleCurrentWeight,
              Amount: project.UnitAmount,
              TotalAmount: project.TotalAmount,
              IsLargess: timeCard.IsLargess,
              TimeCardTreatHandler: handle,
            });
          });
        });

        item.packageGeneralList.forEach((generalCard) => {
          generalCard.Projects.forEach((project) => {
            var handle = [];
            project.HandleTypeList.forEach((handleType) => {
              handleType.Employee.forEach((hand) => {
                handle.push({
                  TreatHandlerID: handleType.ID,
                  EmployeeID: hand.EmployeeID,
                  Scale: hand.Discount,
                });
              });
            });

            treatParams.GeneralCard.push({
              GeneralCardAccountID: generalCard.ID,
              ProjectID: project.ID,
              Quantity: project.Quantity,
              Price: project.Price,
              CardTreatTimes: Number(project.ConsumeAmount) * Number(project.Quantity),
              Amount: project.UnitAmount,
              TotalAmount: project.TotalAmount,
              IsLargess: generalCard.IsLargess,
              GeneralCardTreatHandler: handle,
            });
          });
        });
      });

      return treatParams;
    },
    /**  继续开单  */
    continueCreateConsumeOrder: function() {
      var that = this;
      that.remark = "";
      that.dialoConsumeSucceed = false;
    },

    /**  搜索消耗商品  */
    searchGoodsClick: function() {
      var that = this;
      if (that.customerID == null) {
        that.$message.error("请填写会员信息");
        return;
      }
      that.tabPane = "";
      that.treatProjectAccount();
      that.treatSavingCardAccountNet();
      that.treatPackageCardAccounttNet();
      that.treatTimeCardAccountNet();
      that.treatGeneralCardAccountNet();
      that.treatProductAccount();
    },

    /**  清空搜索内容  */
    clearClick: function() {
      var that = this;
      that.searchGoodsClick();
    },

    /**  开单时间  */
    getBillDate: function() {
      var that = this;
      return that.isSupplement ? that.billDate : date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss");
    },

    /**  经手人选择事件  */
    // 点击选择经手人呢 0 项目 1、产品 2、储值卡 3、 套餐卡 4、时效卡 5、通用次卡
    employeeHandleClick: function(type, item, handleIndex) {
      var that = this;
      that.itemList = item;
      console.log(that.itemList);
      that.tabHandle = "0";
      switch (type) {
        case 1:
          that.treatHandlerList = that.productTreatHandlers;
          break;
        case 2:
          that.treatHandlerList = that.savingCardTreatHandlers;
          break;

        case 0:
        case 4:
        case 5:
          that.treatHandlerList = that.projectTreatHandlers;
          break;
      }
      var emplayee = [];
      item.HandleTypeList.forEach(function(hand) {
        hand.Employee.forEach(function(emp) {
          emplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      that.treatHandlerList.forEach(function(handler) {
        handler.Employee.forEach(function(emp) {
          emp.Checked = false;
          emp.Discount = "";
          emplayee.forEach(function(i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });
      // that.tabHandle =  handleIndex.toString()
      console.log("handleIndex", handleIndex);
      console.log("that.tabHandle", that.tabHandle);
      that.dialogVisible = true;
      that.cardSelectType = type;
      that.cardSelectItem = item;
    },
    /**  经手人确认事件  */
    submitHandleClick: function() {
      var that = this;
      var goodsHandlerList = JSON.parse(JSON.stringify(that.treatHandlerList));
      goodsHandlerList.forEach(function(item) {
        item.Employee = Enumerable.from(item.Employee)
          .where(function(i) {
            return i.Checked;
          })
          .toArray();
      });
      switch (that.cardSelectType) {
        case 0:
          that.cardSelectItem.HandleTypeList = [];
          that.cardSelectItem.HandleTypeList = goodsHandlerList;
          break;

        case 1:
          that.cardSelectItem.HandleTypeList = [];
          that.cardSelectItem.HandleTypeList = goodsHandlerList;
          break;
        case 2:
          that.cardSelectItem.HandleTypeList = [];
          that.cardSelectItem.HandleTypeList = goodsHandlerList;

          break;

        case 3:
          that.cardSelectItem.HandleTypeList = [];
          that.cardSelectItem.HandleTypeList = goodsHandlerList;

          break;

        case 4:
          that.cardSelectItem.HandleTypeList = [];
          that.cardSelectItem.HandleTypeList = goodsHandlerList;
          break;
        case 5:
          that.cardSelectItem.HandleTypeList = [];
          that.cardSelectItem.HandleTypeList = goodsHandlerList;
          break;

        default:
          break;
      }

      that.dialogVisible = false;
    },

    // 经手人选择
    handlerCheckedChange: function(row, item) {
      var that = this;
      console.log(row, item);
      var discount = 0;
      if (that.itemList.TargetWeight) {
        item.Discount = 0;
        return;
      }
      var employee = Enumerable.from(row)
        .where(function(i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function(emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },
    // 百分比
    handlerPercentChange: function(row, item) {
      var that = this;
      var discount = 0;
      if (item.Discount != "") {
        item.Discount = parseFloat(item.Discount);
      }
      if (item.Discount > 100) {
        item.Discount = 100;
      }
      var employee = Enumerable.from(row)
        .where(function(i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function(emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
    },
    // 删除经手人
    removeHandleClick: function(item, index) {
      item.Employee.splice(index, 1);
    },
    /** 数据网络请求  */
    /**  52.1.项目存量列表  */
    treatProjectAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.treatGoodsProjectAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatProjectAccountList = res.Data;
            if (that.tabPane == "" && that.treatProjectAccountList.length > 0) {
              that.tabPane = "0";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /**  52.2.产品存量列表  */
    treatProductAccount: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.searchName,
      };
      API.treatGoodsProductAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatProductAccountList = res.Data;
            if (that.tabPane == "" && that.treatProductAccountList.length > 0) {
              that.tabPane = "5";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.3.储值卡存量列表  */
    treatSavingCardAccountNet: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Name: that.searchName,
      };
      API.treatGoodsSavingCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatSavingCardAccountList = res.Data;
            if (that.tabPane == "" && that.treatSavingCardAccountList.length > 0) {
              that.tabPane = "1";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.4.储值卡消耗适用项目  */
    /**  isInit：是否初始化，点击储值卡传true，搜索传false  */
    treatSavingCardAccountProjectNet: function(isInit) {
      var that = this;
      that.loading = true;
      var params = {
        Type: that.selectSavingcardItem.Type,
        SavingCardID: that.selectSavingcardItem.SavingCardID,
        Name: that.searchSavingcardProjectName,
      };
      API.treatGoodsSavingCardAccountProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.currentSavingcardProjectCategoty = res.Data;
            if (isInit) {
              if (that.currentSavingcardProjectCategoty.length > 0) {
                that.dialogSavingCard = true;
              } else {
                that.$message.error({
                  message: "当前储值卡未配置项目，请配置项目再消耗。",
                  duration: 2000,
                });
                return;
              }
            }
            that.currentSavingcardProjectCategoty.forEach((cItem) => {
              cItem.Child.forEach((item) => {
                if (item.IsGoods == "0") {
                  item.Child.forEach((element) => {
                    if (element.PriceType == "1") {
                      element.PreferentialPrice = Number(element.Price * element.DiscountPrice).toFixed(2);
                      element.discount = element.DiscountPrice * 10; //计算几折
                      if (element.discount == 10) {
                        element.discountName = "无折扣";
                      } else {
                        element.discountName = element.discount + "折";
                      }
                      element.CardPreferentialAmount = element.Price - element.PreferentialPrice;
                    }

                    if (element.PriceType == "2") {
                      element.PreferentialPrice = element.DiscountPrice;
                      element.discount = (element.DiscountPrice / element.Price) * 10;
                      if (element.discount == 10) {
                        element.discountName = "无折扣";
                      } else {
                        element.discountName = element.discount + "折";
                      }
                      element.CardPreferentialAmount = element.Price - element.PreferentialPrice;
                    }
                  });
                }
              });
            });

            if (that.currentSavingcardProjectCategoty.length > 0) {
              that.currentCategoryIndex = 0;
              that.currentSavingcardProject = that.currentSavingcardProjectCategoty[that.currentCategoryIndex].Child;
              that.currentSavingActiveName = Enumerable.from(that.currentSavingcardProject)
                .select((val) => val.ID)
                .toArray();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /**  52.5.通用次卡存量列表  */
    treatGeneralCardAccountNet: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Name: that.searchName,
      };
      API.treatGoodsGeneralCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatGeneralCardAccountList = res.Data;
            if (that.tabPane == "" && that.treatGeneralCardAccountList.length > 0) {
              that.tabPane = "3";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.6.通用次卡消耗适用项目  */
    treatGeneralCardAccountProjectNet: function(isInit) {
      var that = this;
      that.loading = true;
      var params = {
        GeneralCardID: that.selectGeneralcardItem.GeneralCardID,
        Name: that.searchGeneralcarPorjectName,
        GeneralCardAccountID: that.selectGeneralcardItem.ID,
      };
      API.treatGoodsGeneralCardAccountProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.currentGeneralcarProjectCategoty = res.Data;
            if (isInit) {
              if (that.currentGeneralcarProjectCategoty.length > 0) {
                that.dialogGeneralCard = true;
              } else {
                that.$message.error({
                  message: "当前通用次卡未配置项目，请配置项目再消耗。",
                  duration: 2000,
                });
                return;
              }
            }

            if (that.currentGeneralcarProjectCategoty.length > 0) {
              that.currentCategoryIndex = 0;
              that.currentGeneralcarProject = that.currentGeneralcarProjectCategoty[that.currentCategoryIndex].Child;
              // that.currentGeneralcarActiveName =
              //   that.currentGeneralcarProject[0].ID;

              that.currentGeneralcarActiveName = Enumerable.from(that.currentGeneralcarProject)
                .select((val) => val.ID)
                .toArray();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.7.时效卡存量列表 */
    treatTimeCardAccountNet: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Name: that.searchName,
      };
      API.treatGoodsTimeCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatTimeCardAccountList = res.Data;
            if (that.tabPane == "" && that.treatTimeCardAccountList.length > 0) {
              that.tabPane = "2";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.8.时效卡消耗适用项目 */
    treatTimeCardAccountProjectNet: function(isInit, item) {
      console.log(item);
      var that = this;
      that.loading = true;
      var params = {
        TimeCardID: that.selectTimecardItem.TimeCardID,
        Name: that.searchTimeCardPorjectName,
      };
      API.treatGoodsTimeCardAccountProject(params)
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            that.currentTimeCardProjectCategoty = res.Data;
            for (let i in that.currentTimeCardProjectCategoty) {
              for (let j in that.currentTimeCardProjectCategoty[i].Child) {
                console.log(that.currentTimeCardProjectCategoty[i].Child[j]);
                for (let t in that.currentTimeCardProjectCategoty[i].Child[j].Child) {
                  console.log(that.currentTimeCardProjectCategoty[i].Child[j].Child[t]);
                  that.$set(that.currentTimeCardProjectCategoty[i].Child[j].Child[t], "TargetWeight", item.TargetWeight);
                  that.$set(that.currentTimeCardProjectCategoty[i].Child[j].Child[t], "CustomerTargetWeight", item.CustomerTargetWeight);
                  that.$set(that.currentTimeCardProjectCategoty[i].Child[j].Child[t], "MinWeight", item.MinWeight);
                  that.$set(that.currentTimeCardProjectCategoty[i].Child[j].Child[t], "SaleCurrentWeight", item.SaleCurrentWeight);
                }
              }
            }
            console.log(that.currentTimeCardProjectCategoty);

            if (isInit) {
              if (that.currentTimeCardProjectCategoty.length > 0) {
                that.dialogTimeCard = true;
              } else {
                that.$message.error({
                  message: "当前时效卡未配置项目，请配置项目再消耗。",
                  duration: 2000,
                });
                return;
              }
            }
            if (that.currentTimeCardProjectCategoty.length > 0) {
              that.currentCategoryIndex = 0;
              that.currentTimeCardProject = that.currentTimeCardProjectCategoty[that.currentCategoryIndex].Child;
              // that.currentTimeCardActiveName =
              //   that.currentTimeCardProject[0].ID;
              that.currentTimeCardActiveName = Enumerable.from(that.currentTimeCardProject)
                .select((val) => val.ID)
                .toArray();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.9.套餐卡存量列表 */
    treatPackageCardAccounttNet: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Name: that.searchName,
      };
      API.treatGoodsPackageCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatGoodsPackageCardAccountList = res.Data;
            if (that.treatGoodsPackageCardAccountList.length > 0) {
              that.currentSelectPackageItem = that.treatGoodsPackageCardAccountList[0];
              (that.currentPackCategoryIndex = 0), that.treatPackageCardAccountDetailsNet();
            }
            if (that.tabPane == "" && that.treatGoodsPackageCardAccountList.length > 0) {
              that.tabPane = "4";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.10.套餐卡存量明细 */
    treatPackageCardAccountDetailsNet: function() {
      var that = this;
      that.packageDetailLoading = true;
      var params = {
        PackageCardAccountID: that.currentSelectPackageItem.ID,
        BillDate: that.getBillDate(),
      };
      that.treatGoodsPackageCardAccountDetailsList = {};
      API.treatGoodsPackageCardAccountDetails(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatGoodsPackageCardAccountDetailsList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.packageDetailLoading = false;
        });
    },
    /**  52.11.获取项目消耗经手人 */
    treatProjectHandlerNet: function() {
      var that = this;
      that.loading = true;
      var params = {};
      API.treatGoodsProjectHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectTreatHandlers = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.12.获取产品消耗经手人 */
    treatProductHandlerNet: function() {
      var that = this;
      that.loading = true;
      var params = {};
      API.treatGoodsProductHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productTreatHandlers = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.13.获取储值卡消耗经手人 */
    treatSavingCardHandlerNet: function() {
      var that = this;
      that.loading = true;
      var params = {
        // GeneralCardID: "",
      };
      API.treatGoodsSavingCardHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardTreatHandlers = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /**  52.14.消耗结账 */
    treatBillCreateNet: function(treatParams) {
      var that = this;
      that.loading = true;

      API.treatBillCreate(treatParams)
        .then((res) => {
          if (res.StateCode == 200) {
            that.consumeTotalAmount = treatParams.Amount;
            that.currentSelectProjectList = [];
            that.currentSelectProductList = [];
            that.currentSelectSavingCardList = [];
            that.currentSelectTimeCardList = [];
            that.currentSelectGeneralCardList = [];
            that.currentselectPackageCardList = [];
            that.remark = "";
            that.changMemberOrType();
            that.consumeBillNumber = res.Message;
            that.dialoConsumeSucceed = true;
            that.$parent.IsSupplement = false;
            that.$parent.BillDate = null;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /**消耗 详情 数据    */
    showTreatInfo: function(row) {
      var that = this;
      let params = {
        ID: that.consumeBillNumber,
      };
      that.printLoading = true;
      orderAPI
        .treatBillinfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.treatInfo = res.Data;
            that.treatCashierReceiptDialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.printLoading = false;
          that.getReceiptConfig();
        });
    },
    /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      cashierAPI
        .getReceiptConfigBill()
        .then((res) => {
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          }
        })
        .finally(() => {});
    },

    /**  获取 消耗打印内容  */
    printTreatBillContent() {
      let that = this;
      let params = {
        ID: that.treatInfo.ID,
      };
      cashierAPI
        .printTreatBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (let index = 0; index < that.cashierReceipt.PrintQuantity; index++) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    mathAbsData: function(item) {
      return Math.abs(item);
    },
    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },
    // 获取 当前卡项的序号
    getCurrentCardLength(savingCards, index, chileIndex) {
      let number = chileIndex + 1;
      for (let i = 0; i < savingCards.length; i++) {
        const element = savingCards[i];
        if (i < index) {
          number += element.Project.length;
        }
      }
      return number;
    },

    /**  卡项中项目总长度  */
    getCardTotalLength(Cards) {
      let number = 0;
      for (let i = 0; i < Cards.length; i++) {
        const element = Cards[i];
        number += element.Project.length;
      }
      return number;
    },
  },
};
</script>

<style lang="scss">
.consume_content {
  font-size: 13px;
  height: 100%;
  .project_left {
    border-right: 1px solid #eee;
    height: 100%;
    color: #303133;
    .el-tabs {
      height: calc(100% - 62px);
      .el-tabs__header {
        margin: 0;
        .el-tabs__nav-scroll {
          // padding-left: 15px;
          margin-left: 15px;
        }
      }
      .el-tabs__content {
        height: calc(100% - 40px);
        .el-tab-pane {
          height: 100%;
          .col_border {
            // border-right: 1px solid #eee;
            padding: 5px;
            color: #666;
            font-size: 13px;
          }
          .category_project {
            height: 100%;
            .category {
              height: 100%;
              .el-scrollbar_height {
                height: 100%;
              }
            }

            .project {
              height: 100%;
              overflow: auto;
              .producct_list {
                height: 100%;
                .el-scrollbar_height {
                  height: 100%;
                }
              }
            }
          }
          .el-popover-botton-tip {
            padding: 0px;
          }
        }
      }
    }
  }
  .project_right {
    height: 100%;
    .el-icon-sort {
      color: #666;
      font-size: 20px;
      transform: rotate(90deg);
    }
    .el-main {
      padding: 0;
      .row_header {
        background-color: #ecf8ff;
        padding: 10px;
      }
      .employee_num {
        width: 90px;
        .el-input-group__append {
          padding: 0 10px;
        }
      }
      .el-form-item__label {
        font-size: 13px !important;
      }
      .back_project_col {
        background-color: #fafafa;
      }
    }
    .el-footer {
      height: initial !important;
      padding: 10px;
    }
    .el-card__header {
      padding: 10px 10px;
      background-color: #f5f7fa;
    }
    .el-card {
      border-radius: 0px;
    }
    .el-card__body {
      padding: 0px;
    }
  }
}

.consumeGoods {
  .goods-item {
    line-height: 36px;
    font-size: 12px;
    margin-left: 20px;
    margin-right: 5px;
  }
  .goods-lable {
    color: #606266;
  }

  .el-card__header {
    padding: 10px 20px;
    background-color: #f5f7fa;
  }
}

.dialog_bill_detail {
  background-color: #ecf8ff;
  padding: 15px;
  border-radius: 5px;
}

.el-scrollbar_height {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.el-scrollbar_x_width {
  .el-scrollbar__wrap {
    .el-scrollbar__view {
      white-space: nowrap;
      .el-menu-item {
        display: inline-block;
      }
      .is-active {
        a {
          color: #409eff;
        }
      }
    }
  }
}

.valueCard_project {
  height: 430px;
  .category {
    height: 100%;
    .row_header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 600;
      color: #333333;
      height: 45px;
      width: 100%;
      // border-bottom: 1px solid #eeeeee;
      // border-right: 1px solid #eeeeee;
      background-color: #ecf8ff;
    }
    .el-scrollbar_height {
      height: 100%;
    }

    .category_select {
      color: #039be5;
    }
  }
}

.el-collapse {
  .el-collapse-item__header {
    background-color: #f5f7fa !important;
    padding: 0 10px;
  }
  .el-collapse-item__content {
    padding: 0;
  }
}
.popover-package {
  padding: 0px;
  .el-card__header {
    padding: 10px 20px;
    background-color: #f5f7fa;
  }
  .goods-item {
    line-height: 36px;
    font-size: 12px;
    margin-left: 20px;
    margin-right: 5px;
  }
  .goods-lable {
    color: #606266;
  }
}

.t-through {
  text-decoration: line-through;
}
.headerCard {
  position: relative;
}
.headerCardCha {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.pagintionClass {
  text-align: right;
  margin: 20px 0;
}
</style>
